// DO NOT EDIT THIS FILE DIRECTLY. Autogenerated by "yarn squoosh"
import { SquooshEffectResult } from '../../../dev_scripts/squoosh/types'
export type SquooshedPictureKey =
  | 'illustrations/about-you'
  | 'illustrations/allPets'
  | 'illustrations/appointee'
  | 'illustrations/authority'
  | 'illustrations/blood'
  | 'illustrations/cat'
  | 'illustrations/catPet'
  | 'illustrations/cockPet'
  | 'illustrations/coffin'
  | 'illustrations/cornerstone-vault'
  | 'illustrations/crowd'
  | 'illustrations/dashlaneModal'
  | 'illustrations/death'
  | 'illustrations/deputy'
  | 'illustrations/device'
  | 'illustrations/digitalExecutorOrders'
  | 'illustrations/dog'
  | 'illustrations/dogPet'
  | 'illustrations/dogWithVet'
  | 'illustrations/dollar-skyscrapers'
  | 'illustrations/estate-planning-family'
  | 'illustrations/executors'
  | 'illustrations/family'
  | 'illustrations/file'
  | 'illustrations/financing'
  | 'illustrations/fishPet'
  | 'illustrations/flower'
  | 'illustrations/funeralHero'
  | 'illustrations/future-message'
  | 'illustrations/goodtrust-network'
  | 'illustrations/hamsterPet'
  | 'illustrations/handshake'
  | 'illustrations/handsOnLaptop'
  | 'illustrations/happyDance'
  | 'illustrations/health-agent'
  | 'illustrations/horsePet'
  | 'illustrations/identity'
  | 'illustrations/life-stories'
  | 'illustrations/locked'
  | 'illustrations/lockedSafety'
  | 'illustrations/medicalHero'
  | 'illustrations/memorialize'
  | 'illustrations/memories'
  | 'illustrations/mirror'
  | 'illustrations/mirrorAlt'
  | 'illustrations/mousePet'
  | 'illustrations/oldBlackMan'
  | 'illustrations/parrotPet'
  | 'illustrations/petHero'
  | 'illustrations/physician'
  | 'illustrations/poaHero'
  | 'illustrations/printer'
  | 'illustrations/property'
  | 'illustrations/rabbitPet'
  | 'illustrations/raccoonPet'
  | 'illustrations/ready'
  | 'illustrations/review'
  | 'illustrations/rip'
  | 'illustrations/safe'
  | 'illustrations/selectPersonWoman'
  | 'illustrations/signatures'
  | 'illustrations/snakePet'
  | 'illustrations/testament'
  | 'illustrations/trusted-contact'
  | 'illustrations/twoGirlsOneLaptop'
  | 'illustrations/vault'
  | 'illustrations/vault-2'
  | 'illustrations/vet'
  | 'illustrations/vetTall'
  | 'illustrations/what'
  | 'illustrations/willHero'
  | 'illustrations/witness'

export type PictureSource = {
  type: string
  srcset: string
}

export type SquooshedPictureData = {
  fallbackSrc: string
  sources: PictureSource[]
}

export type SquooshedPictures = Record<SquooshedPictureKey, SquooshedPictureData>

export const squooshedPictures: SquooshedPictures = {
  'illustrations/about-you': {
    fallbackSrc: '/images/illustrations/about-you@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/about-you@320w.png 320w, /images/illustrations/about-you@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/about-you@320w.webp 320w, /images/illustrations/about-you@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/allPets': {
    fallbackSrc: '/images/illustrations/allPets@320w.png',
    sources: [
      { srcset: '/images/illustrations/allPets@320w.png 320w', type: 'image/png' },
      {
        srcset:
          '/images/illustrations/allPets@320w.webp 320w, /images/illustrations/allPets@640w.webp 640w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/appointee': {
    fallbackSrc: '/images/illustrations/appointee@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/appointee@320w.png 320w, /images/illustrations/appointee@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/appointee@320w.webp 320w, /images/illustrations/appointee@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/authority': {
    fallbackSrc: '/images/illustrations/authority@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/authority@320w.png 320w, /images/illustrations/authority@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/authority@320w.webp 320w, /images/illustrations/authority@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/blood': {
    fallbackSrc: '/images/illustrations/blood@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/blood@320w.png 320w, /images/illustrations/blood@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/blood@320w.webp 320w, /images/illustrations/blood@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/cat': {
    fallbackSrc: '/images/illustrations/cat@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/cat@320w.png 320w, /images/illustrations/cat@640w.png 640w, /images/illustrations/cat@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/cat@320w.webp 320w, /images/illustrations/cat@640w.webp 640w, /images/illustrations/cat@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/catPet': {
    fallbackSrc: '/images/illustrations/catPet@320w.png',
    sources: [
      { srcset: '/images/illustrations/catPet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/catPet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/cockPet': {
    fallbackSrc: '/images/illustrations/cockPet@320w.png',
    sources: [
      { srcset: '/images/illustrations/cockPet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/cockPet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/coffin': {
    fallbackSrc: '/images/illustrations/coffin@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/coffin@320w.png 320w, /images/illustrations/coffin@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/coffin@320w.webp 320w, /images/illustrations/coffin@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/cornerstone-vault': {
    fallbackSrc: '/images/illustrations/cornerstone-vault@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/cornerstone-vault@320w.png 320w, /images/illustrations/cornerstone-vault@640w.png 640w, /images/illustrations/cornerstone-vault@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/cornerstone-vault@320w.webp 320w, /images/illustrations/cornerstone-vault@640w.webp 640w, /images/illustrations/cornerstone-vault@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/crowd': {
    fallbackSrc: '/images/illustrations/crowd@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/crowd@320w.png 320w, /images/illustrations/crowd@640w.png 640w, /images/illustrations/crowd@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/crowd@320w.webp 320w, /images/illustrations/crowd@640w.webp 640w, /images/illustrations/crowd@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/dashlaneModal': {
    fallbackSrc: '/images/illustrations/dashlaneModal@320w.png',
    sources: [
      { srcset: '/images/illustrations/dashlaneModal@320w.png 320w', type: 'image/png' },
      {
        srcset:
          '/images/illustrations/dashlaneModal@320w.webp 320w, /images/illustrations/dashlaneModal@640w.webp 640w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/death': {
    fallbackSrc: '/images/illustrations/death@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/death@320w.png 320w, /images/illustrations/death@640w.png 640w, /images/illustrations/death@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/death@320w.webp 320w, /images/illustrations/death@640w.webp 640w, /images/illustrations/death@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/deputy': {
    fallbackSrc: '/images/illustrations/deputy@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/deputy@320w.png 320w, /images/illustrations/deputy@640w.png 640w, /images/illustrations/deputy@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/deputy@320w.webp 320w, /images/illustrations/deputy@640w.webp 640w, /images/illustrations/deputy@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/device': {
    fallbackSrc: '/images/illustrations/device@636w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/device@320w.png 320w, /images/illustrations/device@636w.png 636w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/device@320w.webp 320w, /images/illustrations/device@636w.webp 636w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/digitalExecutorOrders': {
    fallbackSrc: '/images/illustrations/digitalExecutorOrders@640w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/digitalExecutorOrders@320w.png 320w, /images/illustrations/digitalExecutorOrders@640w.png 640w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/digitalExecutorOrders@320w.webp 320w, /images/illustrations/digitalExecutorOrders@640w.webp 640w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/dog': {
    fallbackSrc: '/images/illustrations/dog@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/dog@320w.png 320w, /images/illustrations/dog@640w.png 640w, /images/illustrations/dog@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/dog@320w.webp 320w, /images/illustrations/dog@640w.webp 640w, /images/illustrations/dog@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/dogPet': {
    fallbackSrc: '/images/illustrations/dogPet@320w.png',
    sources: [
      { srcset: '/images/illustrations/dogPet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/dogPet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/dogWithVet': {
    fallbackSrc: '/images/illustrations/dogWithVet@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/dogWithVet@320w.png 320w, /images/illustrations/dogWithVet@640w.png 640w, /images/illustrations/dogWithVet@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/dogWithVet@320w.webp 320w, /images/illustrations/dogWithVet@640w.webp 640w, /images/illustrations/dogWithVet@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/dollar-skyscrapers': {
    fallbackSrc: '/images/illustrations/dollar-skyscrapers@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/dollar-skyscrapers@320w.png 320w, /images/illustrations/dollar-skyscrapers@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/dollar-skyscrapers@320w.webp 320w, /images/illustrations/dollar-skyscrapers@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/estate-planning-family': {
    fallbackSrc: '/images/illustrations/estate-planning-family@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/estate-planning-family@320w.png 320w, /images/illustrations/estate-planning-family@640w.png 640w, /images/illustrations/estate-planning-family@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/estate-planning-family@320w.webp 320w, /images/illustrations/estate-planning-family@640w.webp 640w, /images/illustrations/estate-planning-family@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/executors': {
    fallbackSrc: '/images/illustrations/executors@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/executors@320w.png 320w, /images/illustrations/executors@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/executors@320w.webp 320w, /images/illustrations/executors@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/family': {
    fallbackSrc: '/images/illustrations/family@320w.png',
    sources: [
      { srcset: '/images/illustrations/family@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/family@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/file': {
    fallbackSrc: '/images/illustrations/file@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/file@320w.png 320w, /images/illustrations/file@640w.png 640w, /images/illustrations/file@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/file@320w.webp 320w, /images/illustrations/file@640w.webp 640w, /images/illustrations/file@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/financing': {
    fallbackSrc: '/images/illustrations/financing@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/financing@320w.png 320w, /images/illustrations/financing@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/financing@320w.webp 320w, /images/illustrations/financing@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/fishPet': {
    fallbackSrc: '/images/illustrations/fishPet@320w.png',
    sources: [
      { srcset: '/images/illustrations/fishPet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/fishPet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/flower': {
    fallbackSrc: '/images/illustrations/flower@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/flower@320w.png 320w, /images/illustrations/flower@640w.png 640w, /images/illustrations/flower@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/flower@320w.webp 320w, /images/illustrations/flower@640w.webp 640w, /images/illustrations/flower@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/funeralHero': {
    fallbackSrc: '/images/illustrations/funeralHero@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/funeralHero@320w.png 320w, /images/illustrations/funeralHero@640w.png 640w, /images/illustrations/funeralHero@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/funeralHero@320w.webp 320w, /images/illustrations/funeralHero@640w.webp 640w, /images/illustrations/funeralHero@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/future-message': {
    fallbackSrc: '/images/illustrations/future-message@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/future-message@320w.png 320w, /images/illustrations/future-message@640w.png 640w, /images/illustrations/future-message@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/future-message@320w.webp 320w, /images/illustrations/future-message@640w.webp 640w, /images/illustrations/future-message@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/goodtrust-network': {
    fallbackSrc: '/images/illustrations/goodtrust-network@640w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/goodtrust-network@320w.png 320w, /images/illustrations/goodtrust-network@640w.png 640w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/goodtrust-network@320w.webp 320w, /images/illustrations/goodtrust-network@640w.webp 640w, /images/illustrations/goodtrust-network@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/hamsterPet': {
    fallbackSrc: '/images/illustrations/hamsterPet@320w.png',
    sources: [
      { srcset: '/images/illustrations/hamsterPet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/hamsterPet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/handshake': {
    fallbackSrc: '/images/illustrations/handshake@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/handshake@320w.png 320w, /images/illustrations/handshake@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/handshake@320w.webp 320w, /images/illustrations/handshake@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/handsOnLaptop': {
    fallbackSrc: '/images/illustrations/handsOnLaptop@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/handsOnLaptop@320w.png 320w, /images/illustrations/handsOnLaptop@640w.png 640w, /images/illustrations/handsOnLaptop@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/handsOnLaptop@320w.webp 320w, /images/illustrations/handsOnLaptop@640w.webp 640w, /images/illustrations/handsOnLaptop@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/happyDance': {
    fallbackSrc: '/images/illustrations/happyDance@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/happyDance@320w.png 320w, /images/illustrations/happyDance@640w.png 640w, /images/illustrations/happyDance@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/happyDance@320w.webp 320w, /images/illustrations/happyDance@640w.webp 640w, /images/illustrations/happyDance@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/health-agent': {
    fallbackSrc: '/images/illustrations/health-agent@320w.png',
    sources: [
      { srcset: '/images/illustrations/health-agent@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/health-agent@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/horsePet': {
    fallbackSrc: '/images/illustrations/horsePet@320w.png',
    sources: [
      { srcset: '/images/illustrations/horsePet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/horsePet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/identity': {
    fallbackSrc: '/images/illustrations/identity@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/identity@320w.png 320w, /images/illustrations/identity@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/identity@320w.webp 320w, /images/illustrations/identity@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/life-stories': {
    fallbackSrc: '/images/illustrations/life-stories@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/life-stories@320w.png 320w, /images/illustrations/life-stories@640w.png 640w, /images/illustrations/life-stories@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/life-stories@320w.webp 320w, /images/illustrations/life-stories@640w.webp 640w, /images/illustrations/life-stories@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/locked': {
    fallbackSrc: '/images/illustrations/locked@540w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/locked@320w.png 320w, /images/illustrations/locked@540w.png 540w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/locked@320w.webp 320w, /images/illustrations/locked@540w.webp 540w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/lockedSafety': {
    fallbackSrc: '/images/illustrations/lockedSafety@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/lockedSafety@320w.png 320w, /images/illustrations/lockedSafety@640w.png 640w, /images/illustrations/lockedSafety@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/lockedSafety@320w.webp 320w, /images/illustrations/lockedSafety@640w.webp 640w, /images/illustrations/lockedSafety@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/medicalHero': {
    fallbackSrc: '/images/illustrations/medicalHero@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/medicalHero@320w.png 320w, /images/illustrations/medicalHero@640w.png 640w, /images/illustrations/medicalHero@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/medicalHero@320w.webp 320w, /images/illustrations/medicalHero@640w.webp 640w, /images/illustrations/medicalHero@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/memorialize': {
    fallbackSrc: '/images/illustrations/memorialize@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/memorialize@320w.png 320w, /images/illustrations/memorialize@640w.png 640w, /images/illustrations/memorialize@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/memorialize@320w.webp 320w, /images/illustrations/memorialize@640w.webp 640w, /images/illustrations/memorialize@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/memories': {
    fallbackSrc: '/images/illustrations/memories@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/memories@320w.png 320w, /images/illustrations/memories@640w.png 640w, /images/illustrations/memories@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/memories@320w.webp 320w, /images/illustrations/memories@640w.webp 640w, /images/illustrations/memories@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/mirror': {
    fallbackSrc: '/images/illustrations/mirror@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/mirror@320w.png 320w, /images/illustrations/mirror@640w.png 640w, /images/illustrations/mirror@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/mirror@320w.webp 320w, /images/illustrations/mirror@640w.webp 640w, /images/illustrations/mirror@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/mirrorAlt': {
    fallbackSrc: '/images/illustrations/mirrorAlt@520w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/mirrorAlt@320w.png 320w, /images/illustrations/mirrorAlt@520w.png 520w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/mirrorAlt@320w.webp 320w, /images/illustrations/mirrorAlt@520w.webp 520w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/mousePet': {
    fallbackSrc: '/images/illustrations/mousePet@320w.png',
    sources: [
      { srcset: '/images/illustrations/mousePet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/mousePet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/oldBlackMan': {
    fallbackSrc: '/images/illustrations/oldBlackMan@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/oldBlackMan@320w.png 320w, /images/illustrations/oldBlackMan@640w.png 640w, /images/illustrations/oldBlackMan@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/oldBlackMan@320w.webp 320w, /images/illustrations/oldBlackMan@640w.webp 640w, /images/illustrations/oldBlackMan@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/parrotPet': {
    fallbackSrc: '/images/illustrations/parrotPet@320w.png',
    sources: [
      { srcset: '/images/illustrations/parrotPet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/parrotPet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/petHero': {
    fallbackSrc: '/images/illustrations/petHero@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/petHero@320w.png 320w, /images/illustrations/petHero@640w.png 640w, /images/illustrations/petHero@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/petHero@320w.webp 320w, /images/illustrations/petHero@640w.webp 640w, /images/illustrations/petHero@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/physician': {
    fallbackSrc: '/images/illustrations/physician@320w.png',
    sources: [
      { srcset: '/images/illustrations/physician@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/physician@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/poaHero': {
    fallbackSrc: '/images/illustrations/poaHero@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/poaHero@320w.png 320w, /images/illustrations/poaHero@640w.png 640w, /images/illustrations/poaHero@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/poaHero@320w.webp 320w, /images/illustrations/poaHero@640w.webp 640w, /images/illustrations/poaHero@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/printer': {
    fallbackSrc: '/images/illustrations/printer@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/printer@320w.png 320w, /images/illustrations/printer@640w.png 640w, /images/illustrations/printer@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/printer@320w.webp 320w, /images/illustrations/printer@640w.webp 640w, /images/illustrations/printer@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/property': {
    fallbackSrc: '/images/illustrations/property@1086w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/property@320w.png 320w, /images/illustrations/property@543w.png 543w, /images/illustrations/property@1086w.png 1086w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/property@320w.webp 320w, /images/illustrations/property@543w.webp 543w, /images/illustrations/property@1086w.webp 1086w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/rabbitPet': {
    fallbackSrc: '/images/illustrations/rabbitPet@320w.png',
    sources: [
      { srcset: '/images/illustrations/rabbitPet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/rabbitPet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/raccoonPet': {
    fallbackSrc: '/images/illustrations/raccoonPet@320w.png',
    sources: [
      { srcset: '/images/illustrations/raccoonPet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/raccoonPet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/ready': {
    fallbackSrc: '/images/illustrations/ready@640w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/ready@320w.png 320w, /images/illustrations/ready@640w.png 640w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/ready@320w.webp 320w, /images/illustrations/ready@640w.webp 640w, /images/illustrations/ready@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/review': {
    fallbackSrc: '/images/illustrations/review@520w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/review@320w.png 320w, /images/illustrations/review@520w.png 520w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/review@320w.webp 320w, /images/illustrations/review@520w.webp 520w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/rip': {
    fallbackSrc: '/images/illustrations/rip@750w.png',
    sources: [
      {
        srcset: '/images/illustrations/rip@320w.png 320w, /images/illustrations/rip@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/rip@320w.webp 320w, /images/illustrations/rip@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/safe': {
    fallbackSrc: '/images/illustrations/safe@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/safe@320w.png 320w, /images/illustrations/safe@640w.png 640w, /images/illustrations/safe@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/safe@320w.webp 320w, /images/illustrations/safe@640w.webp 640w, /images/illustrations/safe@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/selectPersonWoman': {
    fallbackSrc: '/images/illustrations/selectPersonWoman@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/selectPersonWoman@320w.png 320w, /images/illustrations/selectPersonWoman@640w.png 640w, /images/illustrations/selectPersonWoman@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/selectPersonWoman@320w.webp 320w, /images/illustrations/selectPersonWoman@640w.webp 640w, /images/illustrations/selectPersonWoman@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/signatures': {
    fallbackSrc: '/images/illustrations/signatures@320w.png',
    sources: [
      { srcset: '/images/illustrations/signatures@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/signatures@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/snakePet': {
    fallbackSrc: '/images/illustrations/snakePet@320w.png',
    sources: [
      { srcset: '/images/illustrations/snakePet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/snakePet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/testament': {
    fallbackSrc: '/images/illustrations/testament@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/testament@320w.png 320w, /images/illustrations/testament@640w.png 640w, /images/illustrations/testament@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/testament@320w.webp 320w, /images/illustrations/testament@640w.webp 640w, /images/illustrations/testament@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/trusted-contact': {
    fallbackSrc: '/images/illustrations/trusted-contact@720w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/trusted-contact@360w.png 360w, /images/illustrations/trusted-contact@720w.png 720w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/trusted-contact@360w.webp 360w, /images/illustrations/trusted-contact@720w.webp 720w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/twoGirlsOneLaptop': {
    fallbackSrc: '/images/illustrations/twoGirlsOneLaptop@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/twoGirlsOneLaptop@320w.png 320w, /images/illustrations/twoGirlsOneLaptop@640w.png 640w, /images/illustrations/twoGirlsOneLaptop@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/twoGirlsOneLaptop@320w.webp 320w, /images/illustrations/twoGirlsOneLaptop@640w.webp 640w, /images/illustrations/twoGirlsOneLaptop@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/vault': {
    fallbackSrc: '/images/illustrations/vault@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/vault@320w.png 320w, /images/illustrations/vault@640w.png 640w, /images/illustrations/vault@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/vault@320w.webp 320w, /images/illustrations/vault@640w.webp 640w, /images/illustrations/vault@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/vault-2': {
    fallbackSrc: '/images/illustrations/vault-2@720w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/vault-2@360w.png 360w, /images/illustrations/vault-2@720w.png 720w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/vault-2@360w.webp 360w, /images/illustrations/vault-2@720w.webp 720w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/vet': {
    fallbackSrc: '/images/illustrations/vet@320w.png',
    sources: [
      { srcset: '/images/illustrations/vet@320w.png 320w', type: 'image/png' },
      { srcset: '/images/illustrations/vet@320w.webp 320w', type: 'image/webp' },
    ],
  },
  'illustrations/vetTall': {
    fallbackSrc: '/images/illustrations/vetTall@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/vetTall@320w.png 320w, /images/illustrations/vetTall@640w.png 640w, /images/illustrations/vetTall@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/vetTall@320w.webp 320w, /images/illustrations/vetTall@640w.webp 640w, /images/illustrations/vetTall@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/what': {
    fallbackSrc: '/images/illustrations/what@889w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/what@320w.png 320w, /images/illustrations/what@889w.png 889w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/what@320w.webp 320w, /images/illustrations/what@889w.webp 889w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/willHero': {
    fallbackSrc: '/images/illustrations/willHero@960w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/willHero@320w.png 320w, /images/illustrations/willHero@640w.png 640w, /images/illustrations/willHero@960w.png 960w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/willHero@320w.webp 320w, /images/illustrations/willHero@640w.webp 640w, /images/illustrations/willHero@960w.webp 960w',
        type: 'image/webp',
      },
    ],
  },
  'illustrations/witness': {
    fallbackSrc: '/images/illustrations/witness@750w.png',
    sources: [
      {
        srcset:
          '/images/illustrations/witness@320w.png 320w, /images/illustrations/witness@750w.png 750w',
        type: 'image/png',
      },
      {
        srcset:
          '/images/illustrations/witness@320w.webp 320w, /images/illustrations/witness@750w.webp 750w',
        type: 'image/webp',
      },
    ],
  },
}

export const previousResults: Record<string, Omit<SquooshEffectResult, 'ruleEffect'> | null> = {
  '/6gp7kgnAAG4vtYYSTax4alq6gLPwO1CgTGCgfyU9AA=': null,
  '/ce3iaj180lPR0FLwJupcvDxYLwa2RkhmCQdcoqWVHs=': null,
  '/eXmiibBizFK7LlnTexVnJ3sXyX4o1p7/QSmJ5NAWkU=': {
    imageKey: 'illustrations/financing',
    srcPath: '/images/illustrations/financing@750w.png',
    width: 750,
  },
  '/fEKfFp3j178pGc8U/4v3iLFLF1sar4BmCuInphWMlk=': null,
  '/I9sCc9cyk0U9YVg0ehXozF2q40KNzkzMW9xHYuePeg=': {
    imageKey: 'illustrations/about-you',
    srcPath: '/images/illustrations/about-you@750w.png',
    width: 750,
  },
  '/jXNeyiEUAAYJywGiiUuHJYZiqXyIyhPhDhal61H44Y=': {
    imageKey: 'illustrations/memorialize',
    srcPath: '/images/illustrations/memorialize@640w.webp',
    width: 640,
  },
  '/uKMWWdFN3pGWbLTjnwCmZnPMqxc0mHxt/CAEu5DR6o=': {
    imageKey: 'illustrations/testament',
    srcPath: '/images/illustrations/testament@640w.png',
    width: 640,
  },
  '/yRQvOhHnLYornGbkCb5Nr0uRXN0HKgIF9vySr65Cig=': {
    imageKey: 'illustrations/vetTall',
    srcPath: '/images/illustrations/vetTall@640w.png',
    width: 640,
  },
  '+A7jw+zz31xP66OltViI2qUvoKlCBaHLTe6do8DHLYg=': null,
  '+kKhEDoo87w+x0MpyN1LQM8uzAXBsvauZfDAzAmdfGM=': null,
  '+L+xNEHXg39tB2wYrH5cS06WcZV9Y0n1fSVkd3A/1FM=': {
    imageKey: 'illustrations/goodtrust-network',
    srcPath: '/images/illustrations/goodtrust-network@320w.webp',
    width: 320,
  },
  '+LHZi2zu+jampb0w1QkaMAg0S3IA20WZ3iMbnV0nKUo=': null,
  '04WZlOgQ79A844siX6q1tCLaXe80Jdkg3W8CoB9ekGc=': {
    imageKey: 'illustrations/safe',
    srcPath: '/images/illustrations/safe@960w.png',
    width: 960,
  },
  '08bfiArYi/rPAwFwW0TnZCxkp3E4hVLM5vHJPiFU7wM=': {
    imageKey: 'illustrations/medicalHero',
    srcPath: '/images/illustrations/medicalHero@320w.webp',
    width: 320,
  },
  '0CImkWGj3kTkW2DqRhp5Whu7NOA6v26xkqcIHamaV4Q=': {
    imageKey: 'illustrations/oldBlackMan',
    srcPath: '/images/illustrations/oldBlackMan@320w.webp',
    width: 320,
  },
  '0fDBOLXSx9kluLQac6ZdX+f3pu8rJDJxSd62drBnYOE=': {
    imageKey: 'illustrations/financing',
    srcPath: '/images/illustrations/financing@750w.webp',
    width: 750,
  },
  '0Vt2/a02WpUqTLxjrZlsNgBl2U6ASh1L5Nv+MXa0Bus=': {
    imageKey: 'illustrations/future-message',
    srcPath: '/images/illustrations/future-message@960w.webp',
    width: 960,
  },
  '0zMnrbCPHxGRa3Z2A/U0Tinhn3DrI6u+bfS6iqzfs0Q=': null,
  '12MeGyE6Lnd7VXlWAzP8J9ZGWcv7cRNRKQGBtvmDT5o=': {
    imageKey: 'illustrations/deputy',
    srcPath: '/images/illustrations/deputy@960w.png',
    width: 960,
  },
  '17JpgalvCOy9/Y+Cu/bnIrmfhoFnowCs1VvZ7RomATQ=': {
    imageKey: 'illustrations/deputy',
    srcPath: '/images/illustrations/deputy@320w.png',
    width: 320,
  },
  '18q5UbFV64PtySmbD92IYCHzW7V8lZYqtZGmOUeuFQc=': {
    imageKey: 'illustrations/future-message',
    srcPath: '/images/illustrations/future-message@960w.png',
    width: 960,
  },
  '1dwPpXpWMr0oV0Wk70TeCUT0pmBTa5xar63cNNpGwTQ=': {
    imageKey: 'illustrations/memorialize',
    srcPath: '/images/illustrations/memorialize@640w.png',
    width: 640,
  },
  '1G95ALIYiyOQDehAasAXDkOphQPl0DCLJjusuDGgy/w=': {
    imageKey: 'illustrations/rip',
    srcPath: '/images/illustrations/rip@750w.png',
    width: 750,
  },
  '1q8wYP+0T4Cr2h414y1jBW9v1ePaBfvEZq76sH3LPno=': {
    imageKey: 'illustrations/allPets',
    srcPath: '/images/illustrations/allPets@640w.webp',
    width: 640,
  },
  '1TcLtZkqZN1zNrmWjbaASU/9EL93cRj3yS7SXDBf7z8=': {
    imageKey: 'illustrations/lockedSafety',
    srcPath: '/images/illustrations/lockedSafety@640w.webp',
    width: 640,
  },
  '1v40dG89aUBkcur9o6mgmN10DaNC3w2U49hDYjh4RwE=': {
    imageKey: 'illustrations/ready',
    srcPath: '/images/illustrations/ready@960w.webp',
    width: 960,
  },
  '1w6zWX6wGyFb/gPeqzphPuIlY/oOtlkO/2E9UyQLihc=': {
    imageKey: 'illustrations/handsOnLaptop',
    srcPath: '/images/illustrations/handsOnLaptop@320w.webp',
    width: 320,
  },
  '1WACnyPUW0NWPuBL3IlXazuwGR1n+GGcKk6k30eOQJE=': {
    imageKey: 'illustrations/mirror',
    srcPath: '/images/illustrations/mirror@960w.webp',
    width: 960,
  },
  '1X++iDCOoXMRbKWFAuyztlDG0a0I5IdDrA1NylPgO7w=': {
    imageKey: 'illustrations/physician',
    srcPath: '/images/illustrations/physician@320w.png',
    width: 320,
  },
  '29LIHhmnJB1pMHtq5CYpX9WeSTaB7J+u+eHDtJKW51E=': {
    imageKey: 'illustrations/cat',
    srcPath: '/images/illustrations/cat@960w.png',
    width: 960,
  },
  '2HmwkXwtsIgFg/9jgBgsoSAS48Nwh2FALwaB0pYC5Zc=': {
    imageKey: 'illustrations/vault',
    srcPath: '/images/illustrations/vault@960w.png',
    width: 960,
  },
  '2KBdnX7nqXvGQs6eO2HlLzvu4NBm9IKUADZQu9BASQ0=': {
    imageKey: 'illustrations/dashlaneModal',
    srcPath: '/images/illustrations/dashlaneModal@320w.webp',
    width: 320,
  },
  '2MKVVwHUyBWBEdgq1JXjWmhkg7zlWavV/uE+WxCNnsE=': {
    imageKey: 'illustrations/happyDance',
    srcPath: '/images/illustrations/happyDance@320w.webp',
    width: 320,
  },
  '2X6FLq05G2A1ILRdhCGy9SirkH8wYcvVDkEpHCjiTZY=': {
    imageKey: 'illustrations/executors',
    srcPath: '/images/illustrations/executors@750w.png',
    width: 750,
  },
  '2X6XEIoLtdMA5MesmNClzoYRlimEqXnFuk2+OejsPPU=': {
    imageKey: 'illustrations/petHero',
    srcPath: '/images/illustrations/petHero@320w.webp',
    width: 320,
  },
  '31Pz4pLGdxdLVSG1yjvZ1d6ejf6YGothU/wrTO7AgUI=': {
    imageKey: 'illustrations/snakePet',
    srcPath: '/images/illustrations/snakePet@320w.png',
    width: 320,
  },
  '336il0A1/8I7YkEfuhiVmmSriYaMdSSZmxtbYcWmnbM=': {
    imageKey: 'illustrations/ready',
    srcPath: '/images/illustrations/ready@320w.webp',
    width: 320,
  },
  '38mSsauARNMF63IJ7ZEH/Y/HI3LRy+ILh09F65l/pHc=': {
    imageKey: 'illustrations/witness',
    srcPath: '/images/illustrations/witness@750w.webp',
    width: 750,
  },
  '3mCjzaRto/Jan/9q1wGy6XvYC+gUeKeZkl3ygyUf/FM=': null,
  '3mIdgOKmxp+095dy81CHg73qb/6Lnfv86lQN3YMBiME=': {
    imageKey: 'illustrations/witness',
    srcPath: '/images/illustrations/witness@320w.webp',
    width: 320,
  },
  '3miPxVUfXBwIjc40t75lfJ5Mf96tlH7LiIIkULGZd8g=': {
    imageKey: 'illustrations/dog',
    srcPath: '/images/illustrations/dog@320w.webp',
    width: 320,
  },
  '3pyGkBu8Yzx19NZnViY6EPosXbEW39o7sVCnQjW7a3w=': {
    imageKey: 'illustrations/goodtrust-network',
    srcPath: '/images/illustrations/goodtrust-network@320w.png',
    width: 320,
  },
  '3wNRJ4NXGKQrQOQ3D8m+p8kzI/sgurMtjUSH2AXzPKw=': {
    imageKey: 'illustrations/appointee',
    srcPath: '/images/illustrations/appointee@320w.png',
    width: 320,
  },
  '44HfCFQ/B1PlHG5zHp7J7n+IfePsQRrPjGJnOs+YKhY=': {
    imageKey: 'illustrations/authority',
    srcPath: '/images/illustrations/authority@750w.webp',
    width: 750,
  },
  '4JAe/aWfDdk7UUlpvntRWD+q+7AX4X1x9hX+UcrMtPs=': {
    imageKey: 'illustrations/lockedSafety',
    srcPath: '/images/illustrations/lockedSafety@320w.png',
    width: 320,
  },
  '4lGve4YcR9Ua5PL7md/93sqbkxM8NtbqRE0FAZtBGl8=': {
    imageKey: 'illustrations/oldBlackMan',
    srcPath: '/images/illustrations/oldBlackMan@640w.png',
    width: 640,
  },
  '4qF60WOrNH8FYiJTOzxycOirGxaxekQjWRzKW7WyXUo=': {
    imageKey: 'illustrations/property',
    srcPath: '/images/illustrations/property@320w.webp',
    width: 320,
  },
  '4RRBp4jwSSo0SBD2h336+EvI9Ir0FK1LJXen7lYcD1I=': {
    imageKey: 'illustrations/dog',
    srcPath: '/images/illustrations/dog@960w.webp',
    width: 960,
  },
  '4Z29eYL1HzcP7mDVVc8JvFU2X5YMwGXAxdiEW1u3vJ8=': {
    imageKey: 'illustrations/what',
    srcPath: '/images/illustrations/what@889w.png',
    width: 889,
  },
  '4zbXQUaubpmOGP3899+aWluAr1eQd3pMQHo28H2hfxM=': null,
  '51dLdCALlBDxPSrwuaQ+AA1F1IP94W9DfU7D3xVdMNI=': {
    imageKey: 'illustrations/coffin',
    srcPath: '/images/illustrations/coffin@750w.webp',
    width: 750,
  },
  '5ESW1uZJ+eHhbiww+gOlakPMEcKTAEWiYAZ6ut6umm0=': {
    imageKey: 'illustrations/identity',
    srcPath: '/images/illustrations/identity@320w.png',
    width: 320,
  },
  '5h4wLLAY2Hq9Io1gkCIO1T1gGOCYfjPpJ4ozPQQBKp4=': null,
  '5U/GK58pcb9tYCVFAQf+aJbaj/z10Egw55VxjK145uU=': null,
  '6C8JEurNcFz6I3eqyAGim8HvzuQSmlZptFajgQPgNzs=': {
    imageKey: 'illustrations/handshake',
    srcPath: '/images/illustrations/handshake@750w.webp',
    width: 750,
  },
  '6IZfS5n7DiR6G5iWcCrSdlHDk2rj/O8HocNMDon7eVw=': {
    imageKey: 'illustrations/poaHero',
    srcPath: '/images/illustrations/poaHero@960w.webp',
    width: 960,
  },
  '6rcKOhLhNha6vUytl5LK78XBsFUV28ojnjO2pBUDMiI=': {
    imageKey: 'illustrations/death',
    srcPath: '/images/illustrations/death@640w.png',
    width: 640,
  },
  '7rRwFca7SiAp9VQzF7kLINQfWMjl6ktRQw96X80xs9w=': {
    imageKey: 'illustrations/mirror',
    srcPath: '/images/illustrations/mirror@640w.png',
    width: 640,
  },
  '7tvCuKLhTGuruA4mQWiiTgTUKYVPNHBLTgIdvowWciM=': {
    imageKey: 'illustrations/flower',
    srcPath: '/images/illustrations/flower@320w.png',
    width: 320,
  },
  '7Ya7vsdvqoSpAJVYv4W+Ezvw+2VmUnXVp7NdumitoFM=': {
    imageKey: 'illustrations/death',
    srcPath: '/images/illustrations/death@960w.png',
    width: 960,
  },
  '8JPsV2AAACWg88Ju0Wc6VWjTyceOmdjAT/k45qAqXOE=': null,
  '8rdH4UBOmmfjIxZcmYpYhXTLHAoKo8L24lrchIEWbqw=': {
    imageKey: 'illustrations/poaHero',
    srcPath: '/images/illustrations/poaHero@320w.png',
    width: 320,
  },
  '8ZF0xDCQhX6MibeBOifRTuhIoyD5t6wcToCwQpshfeY=': null,
  '96CCgmR509i/AsdEB7Ogsx9p14e/im1ivQCyITMYtmE=': {
    imageKey: 'illustrations/medicalHero',
    srcPath: '/images/illustrations/medicalHero@320w.png',
    width: 320,
  },
  '99Av1U+7KwUoXaoJFegTNh4PfNVdoRdUKA7Vk5Au9iw=': {
    imageKey: 'illustrations/blood',
    srcPath: '/images/illustrations/blood@320w.webp',
    width: 320,
  },
  '9eJDRltkhmLgayQ8aJratYCzeFU3fMfGvAhSnkGr6E0=': {
    imageKey: 'illustrations/dogWithVet',
    srcPath: '/images/illustrations/dogWithVet@320w.webp',
    width: 320,
  },
  '9Et1GrukEqaUZxeJNgA/c57XraU16ZpZg1bmOq5L+84=': {
    imageKey: 'illustrations/testament',
    srcPath: '/images/illustrations/testament@320w.png',
    width: 320,
  },
  '9KxC9ZPDkv7r9S7K5ereaIKtCUmC8gNHLaiLV51NiM4=': {
    imageKey: 'illustrations/petHero',
    srcPath: '/images/illustrations/petHero@320w.png',
    width: 320,
  },
  '9n10PvGI2dR5Xowd/byBhuOqJOx33R0aZObP8pDRbdw=': {
    imageKey: 'illustrations/funeralHero',
    srcPath: '/images/illustrations/funeralHero@320w.png',
    width: 320,
  },
  '9QVX/APjz5FYH35Z3uRpxSveJpW8otvkh4gznCsa+VY=': {
    imageKey: 'illustrations/device',
    srcPath: '/images/illustrations/device@320w.png',
    width: 320,
  },
  '9zgrv/CQO8hceOHDHPoDHiQBCOLeoVh6kIBZO/S2FXo=': {
    imageKey: 'illustrations/vetTall',
    srcPath: '/images/illustrations/vetTall@320w.webp',
    width: 320,
  },
  'A5NU/eCmbOVuwjP3QK0yhYpAM+UUemVswMSs93+GDas=': {
    imageKey: 'illustrations/property',
    srcPath: '/images/illustrations/property@543w.webp',
    width: 543,
  },
  'aGHKMgCOFP0GwPw1oO24Cf1AFGCZfO50S7/j6Oous6E=': {
    imageKey: 'illustrations/printer',
    srcPath: '/images/illustrations/printer@960w.png',
    width: 960,
  },
  'AHg85FGSazf16ny7D2oXzJmpnAuF9SWiuNkqMtdt73Y=': {
    imageKey: 'illustrations/financing',
    srcPath: '/images/illustrations/financing@320w.png',
    width: 320,
  },
  'AILPB1B9QUuiM48cFxZTXXLuUIlzlqMaPnBx8K0WsXM=': null,
  'aLtY7KcpIgDzOLm/uIZONuPFgjMVwyFhbMFxtwCIhcU=': {
    imageKey: 'illustrations/handsOnLaptop',
    srcPath: '/images/illustrations/handsOnLaptop@320w.png',
    width: 320,
  },
  'Am6jF1ZZOO4PW9oEt3CJM8ZswtRZL0eCvyJlQ67vSLE=': {
    imageKey: 'illustrations/horsePet',
    srcPath: '/images/illustrations/horsePet@320w.png',
    width: 320,
  },
  'aQEaZeeWBY1t9mFtDHXkR6qjahXchcuWYVhJG/ZXUlI=': null,
  'AXUas5SYFCeUI2F4lnEpyAEiQDxQrPAHbFt5NTstUh4=': {
    imageKey: 'illustrations/parrotPet',
    srcPath: '/images/illustrations/parrotPet@320w.png',
    width: 320,
  },
  'B5gS4wBLZ3+78ZfG0AQLwIQf0kBYQ6JU3AkrxRQflnQ=': null,
  'b6qyWQuosTqWwSj/qS+/abekcLSRnlIJ29Y9AB+UBuY=': {
    imageKey: 'illustrations/testament',
    srcPath: '/images/illustrations/testament@640w.webp',
    width: 640,
  },
  'BaWTEnUrBaht6K8mPrW2sg64gnxjodrA4snYn3Szu1s=': null,
  'BCDNVHNVhmPRNO6YhUrzRd+sWlWUrIHo3kfaqeHoGRc=': null,
  'Bhn7gHORfq06xobpfCM0B1zM2tcFICFU0RVEypW8OyY=': {
    imageKey: 'illustrations/happyDance',
    srcPath: '/images/illustrations/happyDance@960w.webp',
    width: 960,
  },
  'bHsE4xBtuFBDuuHOyCCqSYZyJmFOVyus8ZwkJmbyA9M=': {
    imageKey: 'illustrations/trusted-contact',
    srcPath: '/images/illustrations/trusted-contact@720w.webp',
    width: 720,
  },
  'bitLDhKFlR/e2oofCz2sOKvpQepljRkvXokSn5T9T/4=': null,
  'BJM8qWENs0rzyZG60a9VERBqqKVcWHwUXoglsf3DXQI=': {
    imageKey: 'illustrations/safe',
    srcPath: '/images/illustrations/safe@960w.webp',
    width: 960,
  },
  'bJQuHhlntdzK5q4wnLXmaznls/L/qozuk8EqSLJ53bA=': null,
  'bkeeuCS1r9jNkbRDDPKgFDCv32r/GFBjM82rk37mTio=': {
    imageKey: 'illustrations/memories',
    srcPath: '/images/illustrations/memories@320w.webp',
    width: 320,
  },
  'bmVB1i4VugwKSe9iO7OPuFpqWJ1vd8v4hBhkrKOVk94=': null,
  'bun8dGyaLiggB9T6zJnFP0lo0EE7MiONLbhkyw1MMSo=': null,
  'C+CAp1lUCYCZNwx4JHPHEYq6SlARSy5LvO6eFgz/27w=': {
    imageKey: 'illustrations/happyDance',
    srcPath: '/images/illustrations/happyDance@960w.png',
    width: 960,
  },
  'C7d5HMxNWpl4ejua3YXea18oLezhl6Ky/NdWKQ7YuzA=': null,
  'CbFq03EfeJNzEn2qyOUeKie4kwOMTgk6GsdZ/yVarVs=': {
    imageKey: 'illustrations/lockedSafety',
    srcPath: '/images/illustrations/lockedSafety@960w.webp',
    width: 960,
  },
  'Ci288wv+A12elm6LDEwfDjt1dMqJmTE00pmTQIauGHc=': {
    imageKey: 'illustrations/mirror',
    srcPath: '/images/illustrations/mirror@320w.webp',
    width: 320,
  },
  'Ci3TTvRbdAVZZGYzEuS3qPrrhAaiKwB9G3jBvtu6VcI=': {
    imageKey: 'illustrations/lockedSafety',
    srcPath: '/images/illustrations/lockedSafety@320w.webp',
    width: 320,
  },
  'ckWt6XtcVxb2ixn8I2lwWtN+UrgTER00pkZ9VTXlReM=': {
    imageKey: 'illustrations/fishPet',
    srcPath: '/images/illustrations/fishPet@320w.webp',
    width: 320,
  },
  'cKXykHz0Ja7NNCY+PWtH5bey0VYSWUNfa0xzeAuHU1c=': {
    imageKey: 'illustrations/authority',
    srcPath: '/images/illustrations/authority@750w.png',
    width: 750,
  },
  'clvT1edXkAywQ+2j9WW6azmNjzejjCPtGD5nuURBldI=': null,
  'CNjfntet4///r4jLUtaN8hv/QEOAq08mGPyI9RC/Jvo=': {
    imageKey: 'illustrations/oldBlackMan',
    srcPath: '/images/illustrations/oldBlackMan@320w.png',
    width: 320,
  },
  'CYSrCOYCcZQMspULp4sfZ8zS+C5ZI1tmo+fWZpqcZzU=': null,
  'D329I3IFfXFsU9jMRuL5pECbK2s+Nf2kaLxcxkyyEOo=': {
    imageKey: 'illustrations/family',
    srcPath: '/images/illustrations/family@320w.png',
    width: 320,
  },
  'DBNvA5uQTDGiSJDwWe0p+t4IVqUXVJhFXmbdupSHOOU=': {
    imageKey: 'illustrations/rip',
    srcPath: '/images/illustrations/rip@320w.png',
    width: 320,
  },
  'ddAh9VC+YChqRnv/dEe/FDp4Idn1RznBgwYHpt9g08M=': {
    imageKey: 'illustrations/executors',
    srcPath: '/images/illustrations/executors@750w.webp',
    width: 750,
  },
  'dj1lNjPaIDRK3TY+HLVXS45VcH/7tayXh1MYjn3X89s=': null,
  'dKnkknMHutQANfAGR+R3Vabf3qgBCzqoycOsjwcMsqM=': {
    imageKey: 'illustrations/oldBlackMan',
    srcPath: '/images/illustrations/oldBlackMan@960w.png',
    width: 960,
  },
  'dlH5hWYLMDuvFrikgmw2i/BUmjiT21n7Vk1RUTHF/Lw=': {
    imageKey: 'illustrations/hamsterPet',
    srcPath: '/images/illustrations/hamsterPet@320w.webp',
    width: 320,
  },
  'dqBuALFkALgiKuIeMsJkO3ocG71ZSbDUxElwAo/K9us=': {
    imageKey: 'illustrations/health-agent',
    srcPath: '/images/illustrations/health-agent@320w.webp',
    width: 320,
  },
  'dRyPFzUqjbST4nDagaskCZbLC4lj4/Gjw3z6PCdwGbA=': {
    imageKey: 'illustrations/dollar-skyscrapers',
    srcPath: '/images/illustrations/dollar-skyscrapers@750w.webp',
    width: 750,
  },
  'dSLYdTdGgXWMfMj65/3suaYrAmx5YzGNFl1NvpCqKn0=': {
    imageKey: 'illustrations/vault',
    srcPath: '/images/illustrations/vault@320w.webp',
    width: 320,
  },
  'dvMC8a0G2OgvC6He/UyH1QAO7pyi1j5G/LwbyqxnCpg=': null,
  'DYz8a9Aq+VV47YmwJoHSIxy3HTXk1I7EvJRLNZiNSmk=': {
    imageKey: 'illustrations/rip',
    srcPath: '/images/illustrations/rip@320w.webp',
    width: 320,
  },
  'E/n+oJluLMuIntx1GCbhOmwWbAIKbEbg3oJjGvO3aJM=': {
    imageKey: 'illustrations/file',
    srcPath: '/images/illustrations/file@320w.webp',
    width: 320,
  },
  'EaUWgqJIqC+RJaa/7Jk6WRolpzL10QS2LaNu2vC15XM=': {
    imageKey: 'illustrations/crowd',
    srcPath: '/images/illustrations/crowd@320w.png',
    width: 320,
  },
  'EayXpxGwE4Z/42+iXdR+h7YSDCx7kA0MR7dJ46b8b5s=': {
    imageKey: 'illustrations/mirrorAlt',
    srcPath: '/images/illustrations/mirrorAlt@320w.webp',
    width: 320,
  },
  'EDOBDvqZKPzP23Hy8G4Zv+EaDpntRmMh5ptRzdYfZv8=': {
    imageKey: 'illustrations/what',
    srcPath: '/images/illustrations/what@889w.webp',
    width: 889,
  },
  'EEKLfBgdLGIx4Qgk36FVCjZPPRn7jHF3spENxk61CGU=': {
    imageKey: 'illustrations/oldBlackMan',
    srcPath: '/images/illustrations/oldBlackMan@640w.webp',
    width: 640,
  },
  'EhFLG+qwNerZYYXrUfARHbaZIqPoeT8qLXRG35/WC6o=': null,
  'ehjC5Mmzmyrejqn/yogZf75D02zk0ZMyS6wVvZPFyCw=': null,
  'EJxGZE2jy+jPerU1Mn3jNvHzbTn8OfJj5oW14odAuvA=': {
    imageKey: 'illustrations/trusted-contact',
    srcPath: '/images/illustrations/trusted-contact@360w.png',
    width: 360,
  },
  'EmFlCtVb7IBugovz/XNmeOS9AXs8T6rTcEUR17Jjj3k=': {
    imageKey: 'illustrations/executors',
    srcPath: '/images/illustrations/executors@320w.png',
    width: 320,
  },
  'enmobt06AkF9PgJlJEkquqqMEvYXChF4/FuaF1TOpnI=': {
    imageKey: 'illustrations/dog',
    srcPath: '/images/illustrations/dog@640w.png',
    width: 640,
  },
  'EQjhIqQcHK9U3eh7iHpcOy5jVhlcGBf2SI9UDPDozq8=': {
    imageKey: 'illustrations/review',
    srcPath: '/images/illustrations/review@520w.png',
    width: 520,
  },
  'EyFsv6RBcycjRmQGA8EP44q4jP6FiBTUEmuZl440s7I=': null,
  'f6+ShKrrCB1V985jGOY/CX0XjZFj2K85pL3lUOwoHaY=': {
    imageKey: 'illustrations/locked',
    srcPath: '/images/illustrations/locked@540w.png',
    width: 540,
  },
  'Fd5+0zLGq5CvsRjytQiEDlQeuy8a1+n0T1Xw1DauN/8=': null,
  'feEyxA64ZaxNV7+LC2Mu497DuWhsxis1ocLuFPWF4CI=': {
    imageKey: 'illustrations/twoGirlsOneLaptop',
    srcPath: '/images/illustrations/twoGirlsOneLaptop@640w.webp',
    width: 640,
  },
  'ffyNP4R3bDcdZKmtN88RMXylRhCU2Q0+zC1hgiE3T3w=': null,
  'fggUo8m9JF4vq6lUjUibUVPpdVoZbqwhP+vq59hLQJo=': {
    imageKey: 'illustrations/memories',
    srcPath: '/images/illustrations/memories@960w.webp',
    width: 960,
  },
  'fH/ManM0rfTZZMGAy2owQXNAurrPnG7QaQfmfwBajCc=': {
    imageKey: 'illustrations/flower',
    srcPath: '/images/illustrations/flower@960w.png',
    width: 960,
  },
  'FhRUoLNqgn0DYSn9+R301qGYiRTAL0ZI/ZaNe26e294=': {
    imageKey: 'illustrations/estate-planning-family',
    srcPath: '/images/illustrations/estate-planning-family@320w.webp',
    width: 320,
  },
  'FILD1mkFefajWC8UgxTQOsl0elucaO3CfmnLPP1EJao=': {
    imageKey: 'illustrations/cornerstone-vault',
    srcPath: '/images/illustrations/cornerstone-vault@960w.png',
    width: 960,
  },
  'fpbSH0RvTNBlGkQ1FLfaFkH8y9nUkENdMsTX43UnJGA=': {
    imageKey: 'illustrations/trusted-contact',
    srcPath: '/images/illustrations/trusted-contact@720w.png',
    width: 720,
  },
  'fPQqUKKogd4BiA7uukz7xaSHjYdQyKzSzjyufEiHbQY=': {
    imageKey: 'illustrations/ready',
    srcPath: '/images/illustrations/ready@640w.webp',
    width: 640,
  },
  'fQWUsnoCRrUyr4d/GY5hkG3VPwIuUVhFH11GaOyyTCI=': {
    imageKey: 'illustrations/testament',
    srcPath: '/images/illustrations/testament@320w.webp',
    width: 320,
  },
  'FvpBtpWtO6Cq6xrQVfjqJ5rQBcCgeS2VDL0X3Q//fsc=': {
    imageKey: 'illustrations/about-you',
    srcPath: '/images/illustrations/about-you@320w.png',
    width: 320,
  },
  'FzlB6kOkwCK3Es+mp2nlcUL9HjqrD0oDbnoYvFAroLY=': {
    imageKey: 'illustrations/handsOnLaptop',
    srcPath: '/images/illustrations/handsOnLaptop@640w.png',
    width: 640,
  },
  'g00u/rP1cM9qm+rk3TewEHoN76IgfiCvv+OGvIQfh98=': {
    imageKey: 'illustrations/dogWithVet',
    srcPath: '/images/illustrations/dogWithVet@640w.png',
    width: 640,
  },
  'g32hku1LICHWHSqYurI4h0s40HJW1txwLbYvyKzE8NM=': {
    imageKey: 'illustrations/life-stories',
    srcPath: '/images/illustrations/life-stories@960w.webp',
    width: 960,
  },
  'g3MKu3sQzGy1l1e2PkSUSlhhA714HbFvGZ9dUqPUhsk=': {
    imageKey: 'illustrations/willHero',
    srcPath: '/images/illustrations/willHero@320w.webp',
    width: 320,
  },
  'g6ua28DcZlgBi8G1uxKhGdbKJ434fpA/xYXJJoUDjE4=': {
    imageKey: 'illustrations/future-message',
    srcPath: '/images/illustrations/future-message@320w.webp',
    width: 320,
  },
  'g73swYOOKXhO7n/57j3W4cAK17ZmDsYmHg224cIXHv0=': null,
  'g8W4GCAEaqu+IT2tYJAqil0OlBfcDpC/PPE4weDpOjY=': null,
  'gB+vRmkX/DuqvNyiHi0rqcdXs9ZK3At3o8aTlQFoBoQ=': {
    imageKey: 'illustrations/twoGirlsOneLaptop',
    srcPath: '/images/illustrations/twoGirlsOneLaptop@960w.webp',
    width: 960,
  },
  'ghBf27rTIU8LhlhkcEQ3RDuGat6AnITJVTt7Y+Vv6GU=': null,
  'GLhvNMZASZMMaP0hC+ITrMLG5SNCP3nZy/+q6vqHR1Q=': {
    imageKey: 'illustrations/digitalExecutorOrders',
    srcPath: '/images/illustrations/digitalExecutorOrders@640w.png',
    width: 640,
  },
  'gnICZoeg3e8DOAVKalr+5OGcnzRrZlVhxPHbja4w/gE=': {
    imageKey: 'illustrations/poaHero',
    srcPath: '/images/illustrations/poaHero@320w.webp',
    width: 320,
  },
  'GP5iBf4GYmvlYa4bAnyRBRNoUI5SVUpSGtkL4cn1Ehk=': {
    imageKey: 'illustrations/crowd',
    srcPath: '/images/illustrations/crowd@960w.png',
    width: 960,
  },
  'gpLOmsgu/p4w1jvVq/sRLfl1kQDrxRCAlt5cdSu/enI=': {
    imageKey: 'illustrations/dogPet',
    srcPath: '/images/illustrations/dogPet@320w.webp',
    width: 320,
  },
  'gpuQdfK7lSpUrcsv8xd3v6+F6ZPByC64GxhTDLBS5OA=': null,
  'gqrzHbMM8p/ufqb1STGCNomw5q/QHvEI0Fm6ohHFJb8=': {
    imageKey: 'illustrations/printer',
    srcPath: '/images/illustrations/printer@640w.png',
    width: 640,
  },
  'Gtt9bxT622YelYwRy8eAhDNgmlarg/zyuCroiVobFRw=': {
    imageKey: 'illustrations/twoGirlsOneLaptop',
    srcPath: '/images/illustrations/twoGirlsOneLaptop@320w.webp',
    width: 320,
  },
  'gVqFoLlZaSsx6qxx9eITSQr1k/rGi6MTJLyct8Hfmkg=': {
    imageKey: 'illustrations/future-message',
    srcPath: '/images/illustrations/future-message@640w.png',
    width: 640,
  },
  'h4g5NsbiMAK9F0Mb9+HeNQEeB11wUOlz0AWAwMmOxEs=': {
    imageKey: 'illustrations/printer',
    srcPath: '/images/illustrations/printer@640w.webp',
    width: 640,
  },
  'hiVkcsMveU1IYQJeDfYZLRmYmXop18kQVNhg1apXNLU=': {
    imageKey: 'illustrations/appointee',
    srcPath: '/images/illustrations/appointee@750w.webp',
    width: 750,
  },
  'hjPTOC/xOU/5pfY9bhgbYXlgXmQMz3ZcV3E3JxNE/Nc=': {
    imageKey: 'illustrations/health-agent',
    srcPath: '/images/illustrations/health-agent@320w.png',
    width: 320,
  },
  'hs1Dk2SAFzcjDbX+n7CuFF1Al3njSxY3Rt09qvHvAHc=': {
    imageKey: 'illustrations/fishPet',
    srcPath: '/images/illustrations/fishPet@320w.png',
    width: 320,
  },
  'HwSiHFKI2plLlM7uwtbhUi9smDij2S1Sc3rj1tRWNkU=': {
    imageKey: 'illustrations/goodtrust-network',
    srcPath: '/images/illustrations/goodtrust-network@640w.webp',
    width: 640,
  },
  'HzkDYv997prZwaIEemM1G4jutKCszGSTsVJ5/wQVmuM=': {
    imageKey: 'illustrations/cat',
    srcPath: '/images/illustrations/cat@640w.png',
    width: 640,
  },
  'I3PM1MburwZ+aHzaGXSnhs35MIH68CytG6Hp8EE9UwY=': {
    imageKey: 'illustrations/handshake',
    srcPath: '/images/illustrations/handshake@750w.png',
    width: 750,
  },
  'i7zHYlo7kPfL1DC+V0bmjMQg7K8oZkumgaV52HceQOk=': {
    imageKey: 'illustrations/what',
    srcPath: '/images/illustrations/what@320w.webp',
    width: 320,
  },
  'i8ZCUotaerIN9bYTv0cRZovxXASz9a+2uez9TxukG8g=': {
    imageKey: 'illustrations/memories',
    srcPath: '/images/illustrations/memories@960w.png',
    width: 960,
  },
  'IDAGK5KaL0PifSdTSBTnitAEKCVrd+FurIdiaQpBSIs=': {
    imageKey: 'illustrations/oldBlackMan',
    srcPath: '/images/illustrations/oldBlackMan@960w.webp',
    width: 960,
  },
  'idRrvInSeErRJHw8aL03Eoha1tU4Uv8UtRCdi+QE7Ok=': {
    imageKey: 'illustrations/petHero',
    srcPath: '/images/illustrations/petHero@640w.png',
    width: 640,
  },
  'iIkg0jH1oAj+UWPlBHpuJ3vWBbSie1tP0tbiDF/1jrw=': {
    imageKey: 'illustrations/coffin',
    srcPath: '/images/illustrations/coffin@320w.webp',
    width: 320,
  },
  'IJLYqlHtDdTRSQ20f90gfBCA7sm/zwiQrzoD4ua51r4=': {
    imageKey: 'illustrations/crowd',
    srcPath: '/images/illustrations/crowd@640w.webp',
    width: 640,
  },
  'IjR82lLRoHP1cdlKkt+MhNGvCHDTcBHRSYaGxvwkRvc=': {
    imageKey: 'illustrations/funeralHero',
    srcPath: '/images/illustrations/funeralHero@320w.webp',
    width: 320,
  },
  'iLO1oJem60GjFMKtygM+WTNYMKODHgVGOjj2O34RXFw=': {
    imageKey: 'illustrations/selectPersonWoman',
    srcPath: '/images/illustrations/selectPersonWoman@640w.png',
    width: 640,
  },
  'imE4NXXtXljosz0oJa6Cikp6FTHjgnUa5c3Fxe7nFOc=': {
    imageKey: 'illustrations/allPets',
    srcPath: '/images/illustrations/allPets@320w.webp',
    width: 320,
  },
  'iNY7PbGLu+OxKlfIdxHw/CJ1HTepcsGzhipQxAAk2tc=': {
    imageKey: 'illustrations/handsOnLaptop',
    srcPath: '/images/illustrations/handsOnLaptop@960w.png',
    width: 960,
  },
  'iQNLwqDKq1jDFTfmgcOORCpGEDc/ROS+NYjcxpKKmcA=': {
    imageKey: 'illustrations/mousePet',
    srcPath: '/images/illustrations/mousePet@320w.webp',
    width: 320,
  },
  'IqSCC6SURVw+Au9p1Eqsd7kR/ev7CY+k9Nk6j6u6LXs=': {
    imageKey: 'illustrations/medicalHero',
    srcPath: '/images/illustrations/medicalHero@960w.webp',
    width: 960,
  },
  'J15SiS3eIvRIsM198pwKt9VZQF7kimMN3SC+k/XkGco=': {
    imageKey: 'illustrations/rabbitPet',
    srcPath: '/images/illustrations/rabbitPet@320w.webp',
    width: 320,
  },
  'J2mU2kP1ZTukEHYIdgiZAaaSGd0weNCiuYKMQIDe5J4=': {
    imageKey: 'illustrations/future-message',
    srcPath: '/images/illustrations/future-message@320w.png',
    width: 320,
  },
  'j3+Jje73qS1NBjIMzZV3kW+4hChFl4PHOCZURkuXdEY=': {
    imageKey: 'illustrations/review',
    srcPath: '/images/illustrations/review@320w.webp',
    width: 320,
  },
  'j4/FBFM2AkrJxQeU52bbOD8MBu/nggJjZf9yn+8UChw=': {
    imageKey: 'illustrations/death',
    srcPath: '/images/illustrations/death@320w.webp',
    width: 320,
  },
  'JjBuQC9gPx66b+YtA1ltwz7nHDnSgni3UTG5npTBlKA=': {
    imageKey: 'illustrations/crowd',
    srcPath: '/images/illustrations/crowd@960w.webp',
    width: 960,
  },
  'JNZusmxJDbMJk9NKijVPhZslnq26ZW1GeqUwdE3+pho=': {
    imageKey: 'illustrations/about-you',
    srcPath: '/images/illustrations/about-you@750w.webp',
    width: 750,
  },
  'jucAp8ka6w81NS0BcvLtohCl184n+39DZpwupt09vUA=': {
    imageKey: 'illustrations/horsePet',
    srcPath: '/images/illustrations/horsePet@320w.webp',
    width: 320,
  },
  'JVL5gmU0nhiT5dA0FLg/cVJKI0LWK+48e3h8IHDuUSM=': {
    imageKey: 'illustrations/estate-planning-family',
    srcPath: '/images/illustrations/estate-planning-family@640w.png',
    width: 640,
  },
  'Jyt2Yy63HHNFUJ68pFP2iAA8DBN31UIgrvysuZQ9AfM=': {
    imageKey: 'illustrations/property',
    srcPath: '/images/illustrations/property@543w.png',
    width: 543,
  },
  'k+yjTOYZnrKUSSA9HEml0n2GjHSiOHdmmA3nW2fS+8M=': {
    imageKey: 'illustrations/coffin',
    srcPath: '/images/illustrations/coffin@750w.png',
    width: 750,
  },
  'kBc/ZZpsMn32U0h6z/MF1rET0XaVBxjbk/EvzD4Lu0Y=': null,
  'KCkpa3id64IhE1Sj/7oC0P3LPfmubPlW0FciGpwirOs=': null,
  'KfR7WbbVm+/E5WoJ3opqaha6EgFNRHvHNjgPJSCJ3h8=': {
    imageKey: 'illustrations/mirrorAlt',
    srcPath: '/images/illustrations/mirrorAlt@520w.png',
    width: 520,
  },
  'KgwEjQxl2+eFJOeFqMYvo1vbBM3Zw4zUyQKknxwY5Io=': {
    imageKey: 'illustrations/selectPersonWoman',
    srcPath: '/images/illustrations/selectPersonWoman@960w.webp',
    width: 960,
  },
  'kizDw7E8t6JifZl5FRXCb972n6osRXaKPjLAFhqTfOw=': {
    imageKey: 'illustrations/happyDance',
    srcPath: '/images/illustrations/happyDance@320w.png',
    width: 320,
  },
  'KjaHlv1oMzppKMIyJJJOFsU8+y7uZ2t9Mmhi8IVyG/U=': {
    imageKey: 'illustrations/cornerstone-vault',
    srcPath: '/images/illustrations/cornerstone-vault@320w.png',
    width: 320,
  },
  'KrPfNImgFlc2icKKd8dSjqKSAG87WzCLeITAbafLEUA=': {
    imageKey: 'illustrations/blood',
    srcPath: '/images/illustrations/blood@320w.png',
    width: 320,
  },
  'KXP3EVWw9MPDVkpX+YwqEcQPygRuatqvJlk2NTWbPfQ=': {
    imageKey: 'illustrations/vault',
    srcPath: '/images/illustrations/vault@320w.png',
    width: 320,
  },
  'l/8HXvIpEKqJqi+UZrxA0Bb/Lr+T+5un/fFuB+Py6Fg=': {
    imageKey: 'illustrations/willHero',
    srcPath: '/images/illustrations/willHero@960w.png',
    width: 960,
  },
  'L0tky9FeU4FAtWqFG1Wl+5r//qm80rmPhgLm6l1yzvQ=': {
    imageKey: 'illustrations/cat',
    srcPath: '/images/illustrations/cat@640w.webp',
    width: 640,
  },
  'L30pIY2io+GE6rTUFE1D8cjSC4owCxIowGVUso0pgHY=': {
    imageKey: 'illustrations/vault-2',
    srcPath: '/images/illustrations/vault-2@720w.webp',
    width: 720,
  },
  'L3saeblIxCZwx6yYUHAL7DnGnM+vNm4MtSJTzcA0g3Q=': null,
  'LBT6zDI4aeMMpR5pLNs6mbQSyZdGemH3FUBtT/CsrkU=': {
    imageKey: 'illustrations/flower',
    srcPath: '/images/illustrations/flower@640w.png',
    width: 640,
  },
  'LDcxUECYqLwjWIS2UYRujKNDxiLAqBWL5gsFDfzVIF8=': null,
  'LeTGbFVKqRuz0gRrHnKqUaU77azyyDLWor7Dg1nzlbA=': null,
  'Lf7lXk0pZSkb3TGgBau23KU4UOMxogSRZO4vFZ0+XCw=': {
    imageKey: 'illustrations/twoGirlsOneLaptop',
    srcPath: '/images/illustrations/twoGirlsOneLaptop@960w.png',
    width: 960,
  },
  'LfHM8BY6OCMZIcW9BPGzvoKVF0aXU5X/W0WU+qR6XT0=': {
    imageKey: 'illustrations/happyDance',
    srcPath: '/images/illustrations/happyDance@640w.png',
    width: 640,
  },
  'lFwyzZF1y0y88WBP2t1OzM2Xu9yDxYP0uX/qvAm2gbE=': {
    imageKey: 'illustrations/raccoonPet',
    srcPath: '/images/illustrations/raccoonPet@320w.png',
    width: 320,
  },
  'Ln0SaGo+WB5GIeG0VkoOUsZDa4Qa8eEpiiLZTyt3YNY=': {
    imageKey: 'illustrations/identity',
    srcPath: '/images/illustrations/identity@750w.webp',
    width: 750,
  },
  'Ln1FwGE4LB0F/xFt8Ry28PHmrpY1pcK8PKaRnDosxJk=': {
    imageKey: 'illustrations/trusted-contact',
    srcPath: '/images/illustrations/trusted-contact@360w.webp',
    width: 360,
  },
  'LNekGcRxSidc2dFFPQslpIRdhnqU7Ty01KtKXUr5IKw=': null,
  'lRP1Pjfx4IkugOHs2B1B1lr9aSW6b0yP6sisa+P6GsA=': null,
  'lSk5h5/nUNQ6L3RVo/gPb+uQeBv2IcFbADApwURy8No=': {
    imageKey: 'illustrations/dog',
    srcPath: '/images/illustrations/dog@640w.webp',
    width: 640,
  },
  'LTOSXDxn8K4OG4p2Uk8ihSiGDB7d6ASqwIfA1hCYMeU=': null,
  'lXmc8JnFbY+xxMkKGG+EBAHjYQdmu7wZ56TQUkrBXVs=': {
    imageKey: 'illustrations/property',
    srcPath: '/images/illustrations/property@320w.png',
    width: 320,
  },
  'Ly3YmfJNfIasqYBUR7BTeuMc8lNRDHJojcPvSw1zDyA=': {
    imageKey: 'illustrations/flower',
    srcPath: '/images/illustrations/flower@640w.webp',
    width: 640,
  },
  'lYXuOS5arPVvw7+Rml1quumyPjNpBkRyOOt0Swk0xMk=': {
    imageKey: 'illustrations/selectPersonWoman',
    srcPath: '/images/illustrations/selectPersonWoman@640w.webp',
    width: 640,
  },
  'LZt6iO+SH++wqGCPAT94JMILYxEbusFqXfHdj8F20lM=': {
    imageKey: 'illustrations/vetTall',
    srcPath: '/images/illustrations/vetTall@960w.webp',
    width: 960,
  },
  'M0z7vIvZMfBwMiNCloMo4rqxEHvq5gq8ynPLQtd8Hio=': {
    imageKey: 'illustrations/dogWithVet',
    srcPath: '/images/illustrations/dogWithVet@640w.webp',
    width: 640,
  },
  'M3Nz/gx5hyWaH3v9oTefB91w9bc9OR7hzshMb+ks48M=': {
    imageKey: 'illustrations/vault-2',
    srcPath: '/images/illustrations/vault-2@360w.png',
    width: 360,
  },
  'm4+Krg73/Do0I9ng0T5+KnARyRCRfTwvAhkOJx0tlMs=': {
    imageKey: 'illustrations/printer',
    srcPath: '/images/illustrations/printer@320w.webp',
    width: 320,
  },
  'm7Vu2/9ca35EATod2jo3t0EzAJVkcLQ55s1v1M94veM=': {
    imageKey: 'illustrations/life-stories',
    srcPath: '/images/illustrations/life-stories@640w.png',
    width: 640,
  },
  'MDsPzI78WZ7lNNrAOh+A32n5ilBOhAdEB8XsAef/Q3Q=': {
    imageKey: 'illustrations/life-stories',
    srcPath: '/images/illustrations/life-stories@320w.webp',
    width: 320,
  },
  'MDuKjvqHWRglGQoYmFCtSpNu3hvvfw2Mo56N2L1wMxk=': {
    imageKey: 'illustrations/printer',
    srcPath: '/images/illustrations/printer@320w.png',
    width: 320,
  },
  'mDuz1I4XYG7F+bzU3yZp6VAhUGH7RgSFU5sQrglLDZc=': {
    imageKey: 'illustrations/parrotPet',
    srcPath: '/images/illustrations/parrotPet@320w.webp',
    width: 320,
  },
  'mhU87daXJwVyq9dCy0Advnzut5NmLcYrE4vKKjN3em4=': {
    imageKey: 'illustrations/poaHero',
    srcPath: '/images/illustrations/poaHero@640w.png',
    width: 640,
  },
  'MIF/SMZBHh5NgzhIv4wywJ8ZDWaTIznOtPg1uwCm15I=': {
    imageKey: 'illustrations/mirrorAlt',
    srcPath: '/images/illustrations/mirrorAlt@320w.png',
    width: 320,
  },
  'mjV5KJ96DIfq3i2TX2LSJIK+ozxmMRDN4MCQ4pBFk7o=': {
    imageKey: 'illustrations/locked',
    srcPath: '/images/illustrations/locked@320w.png',
    width: 320,
  },
  'MNlte5A+E28zimoSrKVpkfuH5nQxzQBIjIFH+HWEDY0=': {
    imageKey: 'illustrations/dogPet',
    srcPath: '/images/illustrations/dogPet@320w.png',
    width: 320,
  },
  'mosewGmASoYEOd2ACq41NvvTEAqfWz9fNZlmUaBNl7E=': {
    imageKey: 'illustrations/cornerstone-vault',
    srcPath: '/images/illustrations/cornerstone-vault@320w.webp',
    width: 320,
  },
  'mQxNBiCrdZaEzkBnsWZxTL8vO05wS9On3sskVOIt3uk=': {
    imageKey: 'illustrations/vetTall',
    srcPath: '/images/illustrations/vetTall@320w.png',
    width: 320,
  },
  'mR+Fwy0sk44RAsvD8YOoKreSHEut/tVBQ3O6lnZdUK0=': {
    imageKey: 'illustrations/review',
    srcPath: '/images/illustrations/review@520w.webp',
    width: 520,
  },
  'MtUY5KBD2Fu7LptqMDsJh66xH315BUCV4Y9lJiniT6c=': {
    imageKey: 'illustrations/cat',
    srcPath: '/images/illustrations/cat@320w.webp',
    width: 320,
  },
  'mXyYAqjavMlBkGMlfOY5gmWalIjc/LRvLEvLu5BHrFw=': {
    imageKey: 'illustrations/flower',
    srcPath: '/images/illustrations/flower@320w.webp',
    width: 320,
  },
  'MymMeLEErM9MyNAtVvtrV3f/UiUTCTg/Km3yBI85vjU=': {
    imageKey: 'illustrations/dogWithVet',
    srcPath: '/images/illustrations/dogWithVet@960w.webp',
    width: 960,
  },
  'mynDyY3abmHWAcbJrWFWm4+SBxzOX0gL6ODumYfbeJU=': {
    imageKey: 'illustrations/executors',
    srcPath: '/images/illustrations/executors@320w.webp',
    width: 320,
  },
  'MZFS2omAR8cylpbPtJqxTn+3Vvs70D/JVitQbM8rhh4=': {
    imageKey: 'illustrations/locked',
    srcPath: '/images/illustrations/locked@320w.webp',
    width: 320,
  },
  'N0xGvHgILygM/Gt704raE1z6cfusiZrB1W/EW9guAec=': null,
  'NI0leUTCLNQv/YldCHgJdFPljLnvri5PAy+uTFOgCWw=': null,
  'Nj4u8ealcJlnj3fp16Wa5DIEaGRenuVwWbgUdQYkwOE=': {
    imageKey: 'illustrations/funeralHero',
    srcPath: '/images/illustrations/funeralHero@960w.png',
    width: 960,
  },
  'NNiBwajUMh/CRPWkke4KDUi4m1uGNeVXxKqAYPlzCoM=': {
    imageKey: 'illustrations/safe',
    srcPath: '/images/illustrations/safe@320w.png',
    width: 320,
  },
  'NNuC578ySst5/T3xArD+qeIkRnhJpEt3buOhc5Ru9BE=': {
    imageKey: 'illustrations/poaHero',
    srcPath: '/images/illustrations/poaHero@640w.webp',
    width: 640,
  },
  'NOH2/N9VI8TgjttgWBX8h/oxby7kSi4mibTo9bqGgtc=': {
    imageKey: 'illustrations/medicalHero',
    srcPath: '/images/illustrations/medicalHero@960w.png',
    width: 960,
  },
  'nOjK0StpvXRaKksNQNHBYwL5br2C603IfF43M6p8WYU=': {
    imageKey: 'illustrations/willHero',
    srcPath: '/images/illustrations/willHero@960w.webp',
    width: 960,
  },
  'NplyQKNN8qTQKQOUMIXlt62nauxVGu+xdCHwViY+r3E=': {
    imageKey: 'illustrations/mirror',
    srcPath: '/images/illustrations/mirror@640w.webp',
    width: 640,
  },
  'NSonPQq+pZqVilQNYQAXQ4tSjc7RSkv0f5UKBvUHln4=': {
    imageKey: 'illustrations/life-stories',
    srcPath: '/images/illustrations/life-stories@320w.png',
    width: 320,
  },
  'nTeRx3o3+QvFYtCF8Hex2JsgWGbfuttmpVH9fOM9lZs=': {
    imageKey: 'illustrations/dashlaneModal',
    srcPath: '/images/illustrations/dashlaneModal@640w.webp',
    width: 640,
  },
  'nwyvyi3kmi7UQrzxJvMEW975IKDT9XOl5R2vg8yokd8=': {
    imageKey: 'illustrations/blood',
    srcPath: '/images/illustrations/blood@750w.webp',
    width: 750,
  },
  'o9s+ssI+J7k676LL6cOVL30CkISBEOwRONrkv25Cjh4=': {
    imageKey: 'illustrations/estate-planning-family',
    srcPath: '/images/illustrations/estate-planning-family@960w.png',
    width: 960,
  },
  'OcfT96cSVMTFfn0e1F+WWFEBurDrDY57UmXrS1g53Bw=': {
    imageKey: 'illustrations/device',
    srcPath: '/images/illustrations/device@320w.webp',
    width: 320,
  },
  'oi5DV5Blc56YwtsfMig8atyLUBNnWD2/wChFa0YDco8=': {
    imageKey: 'illustrations/dashlaneModal',
    srcPath: '/images/illustrations/dashlaneModal@320w.png',
    width: 320,
  },
  'OINH3ReIsQGodIWrEPE964mzu/kpW0MUx4vLUAvgKKY=': {
    imageKey: 'illustrations/vault',
    srcPath: '/images/illustrations/vault@640w.webp',
    width: 640,
  },
  'oirOVqaubq8lPzRo7dNmnlJhIimK9h2lBLiPzURhPYc=': {
    imageKey: 'illustrations/poaHero',
    srcPath: '/images/illustrations/poaHero@960w.png',
    width: 960,
  },
  'OjNCXTPVDfYPT5FJK9IxqEivhipKYbY4RGHpU3m14Ks=': null,
  'okO/yJOXkO2dsmweCNNXLqv8fP+3tk30QHZS4smyTyI=': {
    imageKey: 'illustrations/device',
    srcPath: '/images/illustrations/device@636w.webp',
    width: 636,
  },
  'oNgczWfvca8CqmuYuLrvDOzjTyGz/b3Uv1ocww5v1Ow=': {
    imageKey: 'illustrations/vetTall',
    srcPath: '/images/illustrations/vetTall@960w.png',
    width: 960,
  },
  'OptPcLhXPBy5QzuqltCytUhKtD39FGdS1cnFI2jGKd8=': {
    imageKey: 'illustrations/selectPersonWoman',
    srcPath: '/images/illustrations/selectPersonWoman@960w.png',
    width: 960,
  },
  'OqBceExWzHHkz3GsJOURYsOp8hFQtGiWaseT1cdxLVw=': null,
  'OrrxcBgG3qlNo1r0CcXAIqrhbXhI9Lg+mSeCVCoA9AQ=': {
    imageKey: 'illustrations/petHero',
    srcPath: '/images/illustrations/petHero@640w.webp',
    width: 640,
  },
  'ORwjIio8AraD9KGItpfjrsFOqao80wnVFNXTUAFM9XY=': {
    imageKey: 'illustrations/goodtrust-network',
    srcPath: '/images/illustrations/goodtrust-network@640w.png',
    width: 640,
  },
  'OSmHrGuOZvSbe7KpG//NUaYDTVD1PQeehF+2ZM4rB+M=': {
    imageKey: 'illustrations/flower',
    srcPath: '/images/illustrations/flower@960w.webp',
    width: 960,
  },
  'OtaYDFM2gZnCbpMRaXEbUmipMPEnl87NvAl/DFFqRyA=': {
    imageKey: 'illustrations/dollar-skyscrapers',
    srcPath: '/images/illustrations/dollar-skyscrapers@320w.png',
    width: 320,
  },
  'oUNsPLrGmlY3A9hj/qSIM9ScOR2e5wQKw9FlvCWGhqE=': {
    imageKey: 'illustrations/appointee',
    srcPath: '/images/illustrations/appointee@750w.png',
    width: 750,
  },
  'P2FiF69V0ZoThDQCMF6Kx3ipA14Os2lH5WWJQsIKl14=': {
    imageKey: 'illustrations/witness',
    srcPath: '/images/illustrations/witness@750w.png',
    width: 750,
  },
  'p8T+uSqmLs9h+cVym9Wtgq2f8Fzp04+3TFFyUzSKLu8=': {
    imageKey: 'illustrations/life-stories',
    srcPath: '/images/illustrations/life-stories@960w.png',
    width: 960,
  },
  'PCGgJYDisV0wVKYVJ8Ji5fen7joN1cA8tuYfM0vRI5s=': null,
  'pCiCyulmStfEzfQmwRts/QrT/9HrpVL0yPacubpWdOs=': null,
  'pDsl4N7XDdYcW0Muk2p53leDx6G60frCRktO1r8Xcd4=': null,
  'Pkyh/JrZBWxjFpy829MNSI5AthDmOClW0Xw/BeAHSSM=': null,
  'pLJsR8az+aCKe7184Pm3w21Whfw1nib2TrdVNu7YAZw=': {
    imageKey: 'illustrations/device',
    srcPath: '/images/illustrations/device@636w.png',
    width: 636,
  },
  'pmJEr5EswVATaAFr+tGwfPB0mbnzkabLW9rBsG4NHTA=': {
    imageKey: 'illustrations/safe',
    srcPath: '/images/illustrations/safe@640w.webp',
    width: 640,
  },
  'PnEMjp6Zk0CjAOP9tGUbNAi9OfgkcG9pw0knY8BdliM=': {
    imageKey: 'illustrations/signatures',
    srcPath: '/images/illustrations/signatures@320w.webp',
    width: 320,
  },
  'pO1s8u1dVuHh3881yZax5zoLgPjDs+zTIiOvsqcW9G4=': null,
  'poeJ0eh7fG4OlfaxNQ+NZKmv65wWummfgD5fNTpaXDo=': {
    imageKey: 'illustrations/safe',
    srcPath: '/images/illustrations/safe@640w.png',
    width: 640,
  },
  'PQCGt/VuPZP8WjYkJifi+tozcTZOidU+SRB67PmMCtA=': {
    imageKey: 'illustrations/rabbitPet',
    srcPath: '/images/illustrations/rabbitPet@320w.png',
    width: 320,
  },
  'ptT63m1reVePQRPQall5UMTmg2TpEDH50ExMwiHLMW0=': {
    imageKey: 'illustrations/physician',
    srcPath: '/images/illustrations/physician@320w.webp',
    width: 320,
  },
  'PuBk+N0wO2bN1qspybIeWDv5ts/1WvUzrFR8g80EauM=': {
    imageKey: 'illustrations/file',
    srcPath: '/images/illustrations/file@640w.png',
    width: 640,
  },
  'PWxavLvAOd2ZJTa15/dVo6nu6mk/uc9U0nuBL0/9q6o=': {
    imageKey: 'illustrations/estate-planning-family',
    srcPath: '/images/illustrations/estate-planning-family@960w.webp',
    width: 960,
  },
  'PYAGoRkHC65bvJPwQWFS+18LS2kH9pmxROMcuZ9o6Zs=': null,
  'Q5Zt7SztDljoFLWfuJlaALLImq3AL9bOkL0zN+BvACY=': {
    imageKey: 'illustrations/rip',
    srcPath: '/images/illustrations/rip@750w.webp',
    width: 750,
  },
  'Q89Ix+ZeHFx+KGD4OcMS11MDhj6LF0gZQDvecKt4wSc=': {
    imageKey: 'illustrations/twoGirlsOneLaptop',
    srcPath: '/images/illustrations/twoGirlsOneLaptop@640w.png',
    width: 640,
  },
  'qd6pmV8JWTqVul1RtVmDkEVfr3lFW2IuwHvXlVU/aS8=': {
    imageKey: 'illustrations/goodtrust-network',
    srcPath: '/images/illustrations/goodtrust-network@960w.webp',
    width: 960,
  },
  'qjRfvwym8cDE0y1dmOls6iOC63PhDJfSH+Pw2xqq2mc=': {
    imageKey: 'illustrations/file',
    srcPath: '/images/illustrations/file@960w.png',
    width: 960,
  },
  'QolmAQkMOd4v5wVu41JcVzv1unCZ56goGCJTLI468vU=': {
    imageKey: 'illustrations/dollar-skyscrapers',
    srcPath: '/images/illustrations/dollar-skyscrapers@320w.webp',
    width: 320,
  },
  'QqE1v3gq8gyxOmasuLsB9PKiqTzHPL7FixNzIjWrl4E=': null,
  'qrpJ+7KbTUnbffJkgejKQwQkQ2n8M6WsjcP3wJ/StoQ=': null,
  'qUaYSVYl3s8Mpo0PftsNBVSs1JFH3X+yTRIUdqxKbXo=': {
    imageKey: 'illustrations/willHero',
    srcPath: '/images/illustrations/willHero@640w.png',
    width: 640,
  },
  'QxeOBIlC5ErrAF/cWuHJB9ZSvXvJD35u3rHeUC3rYt0=': {
    imageKey: 'illustrations/handsOnLaptop',
    srcPath: '/images/illustrations/handsOnLaptop@960w.webp',
    width: 960,
  },
  'qzzSHqYIcnQ/iKNTcz4f8xHvpWIMxM7Vx+MRV72mZtk=': null,
  'R00w8hLAyufqTUeJQnf8bOYEMIODh0gvgSe4rC/5lmE=': {
    imageKey: 'illustrations/vetTall',
    srcPath: '/images/illustrations/vetTall@640w.webp',
    width: 640,
  },
  'R5sBoIVx8qdHNJAbltFkWC4RjllILFrbSnNpyjgCS80=': {
    imageKey: 'illustrations/dollar-skyscrapers',
    srcPath: '/images/illustrations/dollar-skyscrapers@750w.png',
    width: 750,
  },
  'ra0oCD8zUxXCe9Gdd261TtlRU2DRIFYaV5apAzpIxBA=': null,
  'ReRVchy8ZTMoEzg42uTlw3XIguc2qMmE9PvZGNnaPmw=': null,
  'RkKh6t3g2Ale/eGjl9Yt84INMU9I8jHSEWSI25AnXs4=': null,
  'Rnado11XYBJdLUdaEdvKk+I6zG1jgP+N/3GxXvzhntc=': {
    imageKey: 'illustrations/medicalHero',
    srcPath: '/images/illustrations/medicalHero@640w.webp',
    width: 640,
  },
  'RWfJPMWjfFNFqj7MjSi34mOW7B42NVk6i/UMlOBbScA=': {
    imageKey: 'illustrations/memorialize',
    srcPath: '/images/illustrations/memorialize@320w.webp',
    width: 320,
  },
  'rx0l6ArGmGw/82ECW6P/1trykb+ffjdqBrrkQDUrVTA=': {
    imageKey: 'illustrations/snakePet',
    srcPath: '/images/illustrations/snakePet@320w.webp',
    width: 320,
  },
  'rXnCRHqxyI8plUKD5xJjPrbfTnU2BYI3xyA4dbIWLe0=': null,
  'rZx3ZjLVTk87a+8qmlzKWshT27Yfzbs7hl2kPji+Dys=': {
    imageKey: 'illustrations/appointee',
    srcPath: '/images/illustrations/appointee@320w.webp',
    width: 320,
  },
  'S+EU/g+2QV+k1B0eamRnlLlYH9yniCHCLm8gAd10gFk=': {
    imageKey: 'illustrations/financing',
    srcPath: '/images/illustrations/financing@320w.webp',
    width: 320,
  },
  's4uFHYLF+umulHiDGwQnA6bU+de46yZF+PVKxz099gg=': {
    imageKey: 'illustrations/petHero',
    srcPath: '/images/illustrations/petHero@960w.webp',
    width: 960,
  },
  'S7C+svUGCd/kMhrJh0e7kv0FX/h9mVqJgX24sYAIGB8=': {
    imageKey: 'illustrations/review',
    srcPath: '/images/illustrations/review@320w.png',
    width: 320,
  },
  'S9zM7dPOji034C1gL1DimkZJhY5uIJEhfyfrSxNI0aE=': {
    imageKey: 'illustrations/locked',
    srcPath: '/images/illustrations/locked@540w.webp',
    width: 540,
  },
  'SAm3q6z0it+bfSFGXBV0Eu30z25MR/LlTvemSstR/vs=': {
    imageKey: 'illustrations/about-you',
    srcPath: '/images/illustrations/about-you@320w.webp',
    width: 320,
  },
  'sFlBPdt0Og4zvl9v4c755/KJFVUgniWOSR0RzgF2foI=': {
    imageKey: 'illustrations/deputy',
    srcPath: '/images/illustrations/deputy@640w.png',
    width: 640,
  },
  'sjzGMHesYrQNyet92WL3QLYTitk+SpHXTIegSIwoLRY=': {
    imageKey: 'illustrations/handsOnLaptop',
    srcPath: '/images/illustrations/handsOnLaptop@640w.webp',
    width: 640,
  },
  'sKlU+oJz4+LTJA7n91LDLw/Unglqmwd7pwSLIbsHJ+0=': {
    imageKey: 'illustrations/coffin',
    srcPath: '/images/illustrations/coffin@320w.png',
    width: 320,
  },
  'SO3RuHfu8Jq1mCVXS8rh+9fpTydlS8v8EEgjv9RN3tM=': {
    imageKey: 'illustrations/mirror',
    srcPath: '/images/illustrations/mirror@320w.png',
    width: 320,
  },
  'SsdHTQRPKDM9xfzvquL7uohDr8AxGcfSsSVbcSVi0cE=': null,
  'SUCDvPKC4cylac1529EriineJRebPgThw2bRT1/ptwc=': {
    imageKey: 'illustrations/ready',
    srcPath: '/images/illustrations/ready@320w.png',
    width: 320,
  },
  'sWV5/bUSWDncoURFwbLKxWu1qGT20236VqrSNPmcFso=': null,
  'SX4K2Sq5NRpW+lWmzMUHjb2EI34TxbEm07byi4zc9hU=': {
    imageKey: 'illustrations/what',
    srcPath: '/images/illustrations/what@320w.png',
    width: 320,
  },
  't/bOEsEDm5xVD8xVc5zna+8/Pfb/WKPDuXSokGFEzFY=': {
    imageKey: 'illustrations/medicalHero',
    srcPath: '/images/illustrations/medicalHero@640w.png',
    width: 640,
  },
  'T/CNRWZCNlNlvBXFbOKnlCL9Uxv27qFYlRWlIM7px5Q=': {
    imageKey: 'illustrations/allPets',
    srcPath: '/images/illustrations/allPets@320w.png',
    width: 320,
  },
  't/pRWhtqL/j+RomPcd/IP/Do8toaZUc5LCjnuAzuazY=': null,
  't5Ui5IWeAEBmbwnBDactJMscEBlTpfK5hXqHTB3yrek=': {
    imageKey: 'illustrations/property',
    srcPath: '/images/illustrations/property@1086w.png',
    width: 1086,
  },
  't8OK0a+yX6mRVfykQHoVAFs3X3gu54ugk24GZbVrwOY=': {
    imageKey: 'illustrations/file',
    srcPath: '/images/illustrations/file@960w.webp',
    width: 960,
  },
  'tE4DYxHwGPMi/27RUcbgWvN/f3bvGM5IXQyQ4grW2cs=': {
    imageKey: 'illustrations/mirror',
    srcPath: '/images/illustrations/mirror@960w.png',
    width: 960,
  },
  'TLnS+jih0djGECPe3yPEaqcQbjOLx5U5nC4jAK0USbs=': {
    imageKey: 'illustrations/deputy',
    srcPath: '/images/illustrations/deputy@960w.webp',
    width: 960,
  },
  'TMyetOGNmXjOFDnRf0OdGwm6QTEsHhVrA0lwtuk3I1g=': {
    imageKey: 'illustrations/willHero',
    srcPath: '/images/illustrations/willHero@320w.png',
    width: 320,
  },
  'Tq0s084rdWOqF0utDtYkOcZfuTHVdtvzPvHHC1CnFhE=': {
    imageKey: 'illustrations/ready',
    srcPath: '/images/illustrations/ready@640w.png',
    width: 640,
  },
  'tsRmi2MFoApdeVsjSFAoGyhq1FAxPxcFDWR+DjQeGxs=': {
    imageKey: 'illustrations/estate-planning-family',
    srcPath: '/images/illustrations/estate-planning-family@320w.png',
    width: 320,
  },
  'TwoIYTS3Jmc1r+UFo1waOulwa9MbwohI/R6th3OZhRk=': {
    imageKey: 'illustrations/memorialize',
    srcPath: '/images/illustrations/memorialize@960w.png',
    width: 960,
  },
  'tWuZLtDTCY/+saAE6LtFOiPt5nlOrtYYkKO43ZFgoo8=': null,
  'TwYvGs84xU1I6hlh/To4q24eiUoWi/2+ZCdFYZT/q98=': {
    imageKey: 'illustrations/vet',
    srcPath: '/images/illustrations/vet@320w.png',
    width: 320,
  },
  'tY4kbshjOfIxchDV2TvzSmRniDJcQ2TxCgfa0QPg7T8=': {
    imageKey: 'illustrations/raccoonPet',
    srcPath: '/images/illustrations/raccoonPet@320w.webp',
    width: 320,
  },
  'U5C1TwcvTM258/ViK06PuEUElBPa09DMFz3BO84DQAs=': {
    imageKey: 'illustrations/mirrorAlt',
    srcPath: '/images/illustrations/mirrorAlt@520w.webp',
    width: 520,
  },
  'u5HpROF7Y4l26RSgG/r8iOohqURvftKjXu4Im3sw0hE=': {
    imageKey: 'illustrations/witness',
    srcPath: '/images/illustrations/witness@320w.png',
    width: 320,
  },
  'UECpgmEE70Sp91/RzTO4JidG4rVPCgPHj7BRwyGBSNA=': null,
  'uEL9ExmrQF+b+czu5FtUQQW8+hkDbblxPCN/BangovY=': {
    imageKey: 'illustrations/death',
    srcPath: '/images/illustrations/death@640w.webp',
    width: 640,
  },
  'uKS7Smzy1yfPRBZ04pYkEU5Y6pmyePwdKwXY7fSFHqM=': {
    imageKey: 'illustrations/authority',
    srcPath: '/images/illustrations/authority@320w.png',
    width: 320,
  },
  'uOvruzloeEpSVLvNpvw/jyVpLnIiJM39N3eaHXFaT4E=': {
    imageKey: 'illustrations/vault-2',
    srcPath: '/images/illustrations/vault-2@360w.webp',
    width: 360,
  },
  'uPvsvlk9nqKaGXU9LOBDVFV0bow7ANRuVeTS4ecnZrc=': {
    imageKey: 'illustrations/funeralHero',
    srcPath: '/images/illustrations/funeralHero@640w.png',
    width: 640,
  },
  'UtSZ1Nag3jUYlJMR8/SEMMppxuvAjfwgkS9fYeBcVgU=': {
    imageKey: 'illustrations/crowd',
    srcPath: '/images/illustrations/crowd@320w.webp',
    width: 320,
  },
  'UU97ONU+pT9NYciIxodGEazgQXueJ2oOLopCgrWtRaE=': {
    imageKey: 'illustrations/dog',
    srcPath: '/images/illustrations/dog@960w.png',
    width: 960,
  },
  'UxbRgNMmUe9pvfRpDOuCvIrEQXj4abBf/p43BETQ8KM=': {
    imageKey: 'illustrations/memories',
    srcPath: '/images/illustrations/memories@640w.png',
    width: 640,
  },
  'Uye7Akv0TtJ/qpTpBa3rwEzwJ0GR5+7kIPoCwKHINec=': {
    imageKey: 'illustrations/handshake',
    srcPath: '/images/illustrations/handshake@320w.png',
    width: 320,
  },
  'UZrBtUF8RhM0gdjpIY39RjnNeDk2XJe2rUrOf0pseWY=': null,
  'V+QXRAlZ4kP5DgqhEiC01tfymX9U7kmTcRIiOwq11hc=': {
    imageKey: 'illustrations/twoGirlsOneLaptop',
    srcPath: '/images/illustrations/twoGirlsOneLaptop@320w.png',
    width: 320,
  },
  'V8JCwdtMXIHxjIbhoU66yaeRLiCYSAgUXJO+wYj6Egs=': null,
  'VAcwxGuohEAmObTZ2z1rLUbaU5T8NfzcXq7Xo3LJ0+U=': null,
  'VbgZyXDGJL7kMOuJAkixZkZ+sRT0AfFNPoTA/jNn4P4=': null,
  'VDEq0Wu2O734CAKvwAk/fSWTAy72z7OBXGex4dG/iyk=': {
    imageKey: 'illustrations/testament',
    srcPath: '/images/illustrations/testament@960w.webp',
    width: 960,
  },
  'vjHb3QCdxr5tZOk7dDuzaWtFdbbxq1E53t+oF29AouI=': {
    imageKey: 'illustrations/cornerstone-vault',
    srcPath: '/images/illustrations/cornerstone-vault@640w.webp',
    width: 640,
  },
  'vLGMGLj0X36nyDe2UbPmXAbDtYcOZBvUA8mTZ04KyZM=': {
    imageKey: 'illustrations/petHero',
    srcPath: '/images/illustrations/petHero@960w.png',
    width: 960,
  },
  'Vm25Dq7NC1fVSj64wrG2zQjFZF+sKaD8GQLrmnGIgSE=': {
    imageKey: 'illustrations/hamsterPet',
    srcPath: '/images/illustrations/hamsterPet@320w.png',
    width: 320,
  },
  'VtXDEVCl3obdl7zGb3QcNA4XbDkJA/M2Zbe3rNNc4xY=': {
    imageKey: 'illustrations/cornerstone-vault',
    srcPath: '/images/illustrations/cornerstone-vault@960w.webp',
    width: 960,
  },
  'VUOxr22WqOnubQUYin0XgokWfHikLgLtR2P8LVjvi2Q=': {
    imageKey: 'illustrations/dogWithVet',
    srcPath: '/images/illustrations/dogWithVet@960w.png',
    width: 960,
  },
  'VwDVTlHNTcA7gP4tkVHuvDevbSsZoyKQKjkniIFKwps=': null,
  'VwxSYAaB7PBfCQjcTwZSO97yK8kg/Bg5OskeXME8KTw=': {
    imageKey: 'illustrations/handshake',
    srcPath: '/images/illustrations/handshake@320w.webp',
    width: 320,
  },
  'vybA5+rEmRNXcJSOfua5bOWtxGiJkuyvaOSmfpHd9Cc=': {
    imageKey: 'illustrations/authority',
    srcPath: '/images/illustrations/authority@320w.webp',
    width: 320,
  },
  'vZ5R6Rnrnl4VAEwdm9VleGv+KF5XzXsUffmoZ+CdL14=': {
    imageKey: 'illustrations/safe',
    srcPath: '/images/illustrations/safe@320w.webp',
    width: 320,
  },
  'W3jiwxRGZzEe7gKca2B4ShlmDKrHfMDzpfENusfb/ms=': {
    imageKey: 'illustrations/lockedSafety',
    srcPath: '/images/illustrations/lockedSafety@960w.png',
    width: 960,
  },
  'w8OZcWtZ/XSpAxtz+imqbchng/ROt2iTpD6fMLGeG3M=': {
    imageKey: 'illustrations/dog',
    srcPath: '/images/illustrations/dog@320w.png',
    width: 320,
  },
  'w8yjgd3rQw1HaBB2cXyG+UzYmQ1Xe5mX8j7LSkZixUg=': {
    imageKey: 'illustrations/vault-2',
    srcPath: '/images/illustrations/vault-2@720w.png',
    width: 720,
  },
  'WbB7Pr4SQCzHDq9M11OqLbsM1Twi+A9Qs/ylIYWpBiM=': {
    imageKey: 'illustrations/blood',
    srcPath: '/images/illustrations/blood@750w.png',
    width: 750,
  },
  'WDkbbWgx6YefPCDRc/nOuc1ock5m+4x+L5yiWc4i0UI=': {
    imageKey: 'illustrations/willHero',
    srcPath: '/images/illustrations/willHero@640w.webp',
    width: 640,
  },
  'Wepbm306Vm2ej+mT0HHV0P08+qm/71q7cz7r8Aufpf0=': {
    imageKey: 'illustrations/digitalExecutorOrders',
    srcPath: '/images/illustrations/digitalExecutorOrders@320w.png',
    width: 320,
  },
  'wfsrnqKWvMn2Phqv3/TOh0PzOlSJV89cRn5apqOycgU=': {
    imageKey: 'illustrations/cat',
    srcPath: '/images/illustrations/cat@320w.png',
    width: 320,
  },
  'WG3QpL9pCPAcwYTUdljgr297auPidWb/jCjEdxRw+/8=': {
    imageKey: 'illustrations/death',
    srcPath: '/images/illustrations/death@320w.png',
    width: 320,
  },
  'wNlKDbsGALP2waShGnqzudtwKE3RGtNFSfECsnRbjGg=': {
    imageKey: 'illustrations/digitalExecutorOrders',
    srcPath: '/images/illustrations/digitalExecutorOrders@320w.webp',
    width: 320,
  },
  'wNQ9Ur1/0wX2pg2cGiT8/xrhzFuP2ELj5zhaMFa/qZ0=': null,
  'WOHONv7vSjtZnlvMzBnRn4AFWMS0REEgTrsJUnxSGgI=': {
    imageKey: 'illustrations/deputy',
    srcPath: '/images/illustrations/deputy@640w.webp',
    width: 640,
  },
  'WoWNHdE4UbUFxqrREAbeyC63VZ6HSdaQDxJzyyGY0FA=': null,
  'wqKceHa2QEwqQEtG5OS7a9Epva7Vw5eNOE3CHyhEQgg=': {
    imageKey: 'illustrations/vet',
    srcPath: '/images/illustrations/vet@320w.webp',
    width: 320,
  },
  'WQTCvLCjcFmFnI1ADJObulu+0zYsfdsKIlwiszt0NcM=': {
    imageKey: 'illustrations/memorialize',
    srcPath: '/images/illustrations/memorialize@320w.png',
    width: 320,
  },
  'WrESmKWyLPypCHBolKhNcnZ0gqNJoPeG6w+D5fhcHA4=': {
    imageKey: 'illustrations/vault',
    srcPath: '/images/illustrations/vault@960w.webp',
    width: 960,
  },
  'wrxAKmG1vMnkbIyf6rjAMibnzWdtbBwkLEOSgFuU5Uo=': {
    imageKey: 'illustrations/selectPersonWoman',
    srcPath: '/images/illustrations/selectPersonWoman@320w.png',
    width: 320,
  },
  'WS2trq/5aM9Amsbp71t8mxi1hIykWOXPF13UYGkNxfo=': {
    imageKey: 'illustrations/happyDance',
    srcPath: '/images/illustrations/happyDance@640w.webp',
    width: 640,
  },
  'wUIDgIoM+M15h0Sd164TyGcwkrCxO5Hakbt1Ah2zc94=': {
    imageKey: 'illustrations/testament',
    srcPath: '/images/illustrations/testament@960w.png',
    width: 960,
  },
  'WVepYFw3h8nDY2xV4Jx/FPEk+IhUpLjYBinpDPOFYg8=': null,
  'WWDk5UlGceTr5RHxwIiNZ9isOQMAENtJ1oHDqG4KQRc=': {
    imageKey: 'illustrations/property',
    srcPath: '/images/illustrations/property@1086w.webp',
    width: 1086,
  },
  'wWXdL2RdM25cNatNj+zstpU8PIOVEsrbocYloaU60w8=': null,
  'X/sNGNovAx7qSQ0G1L62GNzwd652VoTwY0lL1ex+lE8=': {
    imageKey: 'illustrations/mousePet',
    srcPath: '/images/illustrations/mousePet@320w.png',
    width: 320,
  },
  'xBpitTXF+LzDx3XjlxgRv+/h6FSvI85rAWMZ33hI/EA=': {
    imageKey: 'illustrations/cockPet',
    srcPath: '/images/illustrations/cockPet@320w.webp',
    width: 320,
  },
  'XcRN7OZ3GhZNmosaEBnjboF1Uc49qxOtuzAQqohXqYA=': {
    imageKey: 'illustrations/dogWithVet',
    srcPath: '/images/illustrations/dogWithVet@320w.png',
    width: 320,
  },
  'XEgrOzqCyfU6m1inc0AS/TWbkYm+0sc2r0TNN6Efzjk=': {
    imageKey: 'illustrations/future-message',
    srcPath: '/images/illustrations/future-message@640w.webp',
    width: 640,
  },
  'XEvYV9/egi67xMZPob5vP9gWSYDJcWKw40Q+gJ+N/zo=': {
    imageKey: 'illustrations/deputy',
    srcPath: '/images/illustrations/deputy@320w.webp',
    width: 320,
  },
  'XhA4nryW+SQZ/abcm2prZfKk9XlC/AfW91TAT9xa3r8=': {
    imageKey: 'illustrations/catPet',
    srcPath: '/images/illustrations/catPet@320w.webp',
    width: 320,
  },
  'xJ7LW9WY3bbTRD+hEdjb6/Jx1NMACwJoPIYtKGeE+94=': {
    imageKey: 'illustrations/memorialize',
    srcPath: '/images/illustrations/memorialize@960w.webp',
    width: 960,
  },
  'XJVwGVfborl3IWaqM4seK0irsJVdEPQLr4ot6XPlBZw=': null,
  'xonSJ4WzEwkrSEa+LZ99jFGo/N3DD7JetaRZzCAja40=': {
    imageKey: 'illustrations/funeralHero',
    srcPath: '/images/illustrations/funeralHero@960w.webp',
    width: 960,
  },
  'XVVb5tgQvtKoH6tVRj2qS+F9XJpday8Zw7svAVy1khQ=': {
    imageKey: 'illustrations/memories',
    srcPath: '/images/illustrations/memories@320w.png',
    width: 320,
  },
  'Xzm8liniyPd3yxm29VmJkoSn5pNsjO8Z9ENx8ene/vw=': {
    imageKey: 'illustrations/signatures',
    srcPath: '/images/illustrations/signatures@320w.png',
    width: 320,
  },
  'y+RuWMUImD29ZVBfJM5AT4SbYmA7mGIig6EaITBmnCY=': {
    imageKey: 'illustrations/catPet',
    srcPath: '/images/illustrations/catPet@320w.png',
    width: 320,
  },
  'y0d6d6tC6LmOJ9EVuhWQjVg4GPtSfyozAHvUY3VgoNo=': null,
  'y0Gj6e89X2/aj95RO8plAYCSesnYeUd6waBI52D6D14=': {
    imageKey: 'illustrations/file',
    srcPath: '/images/illustrations/file@640w.webp',
    width: 640,
  },
  'Y2pAuGl/mCjh4AOckvHxoGJd1hSRl5gw14WP6dg1v5A=': {
    imageKey: 'illustrations/identity',
    srcPath: '/images/illustrations/identity@320w.webp',
    width: 320,
  },
  'Y3Gr6pX7xbZs3jYpsab8Nj4FzG+bm7JAnCMDGweyh0U=': null,
  'y5yJCjkzEoMInXvg/Jn5FypvbiK0WMFj5TmPYKWYWno=': {
    imageKey: 'illustrations/memories',
    srcPath: '/images/illustrations/memories@640w.webp',
    width: 640,
  },
  'yBkDqjBLICYXn92lqjNveApRH/pSB+aOyIX5+Q/hg2U=': null,
  'YcmF79MwkA/J+5ULXQRsYI1reuTrF4d4PwvqDQkMino=': null,
  'YDXfxc3qXfn97ILxIQmUTG2j/pvYViF5ue+UwPca1Sw=': null,
  'yE4FF70bixZzLkYK7NnZ4KZpuSZsnLlc8c1nuCCCQEg=': {
    imageKey: 'illustrations/selectPersonWoman',
    srcPath: '/images/illustrations/selectPersonWoman@320w.webp',
    width: 320,
  },
  'YFx3hqJ9WBgqAUqPx9dA1xwKnP9YsfGnmfDroEVaB+8=': {
    imageKey: 'illustrations/cockPet',
    srcPath: '/images/illustrations/cockPet@320w.png',
    width: 320,
  },
  'YKNgn9BqdprFpLYBcw5UYH5FYTXymhuWtF3tr6uMSQY=': {
    imageKey: 'illustrations/death',
    srcPath: '/images/illustrations/death@960w.webp',
    width: 960,
  },
  'YLK00zYwvXTNHVdm5mrP7ZKiCLRHTFmIv3zFjQ1ORCI=': null,
  'YoMZTG0yiVRDlHK/M2CQ4ztw3a8LHQlyD0S0WWuefTg=': {
    imageKey: 'illustrations/printer',
    srcPath: '/images/illustrations/printer@960w.webp',
    width: 960,
  },
  'ypFIdSRgaqwNak8to0SFtbxkrRr7K6cUuTuuJJ0ZxtI=': {
    imageKey: 'illustrations/life-stories',
    srcPath: '/images/illustrations/life-stories@640w.webp',
    width: 640,
  },
  'yv7LOFiMGj3SqaLkvcXZEBHerYJby3zhAn5Ofj0KO/0=': {
    imageKey: 'illustrations/funeralHero',
    srcPath: '/images/illustrations/funeralHero@640w.webp',
    width: 640,
  },
  'z+dAHpDBnurL1oQL8nbySJQTL3GfMrt1osuAttwa6eY=': null,
  'Z1zgdkXumcb+HPJQ/XNFcu9QWr3FDq6dlb4GoNRJass=': {
    imageKey: 'illustrations/cornerstone-vault',
    srcPath: '/images/illustrations/cornerstone-vault@640w.png',
    width: 640,
  },
  'z82rS89NUzQqvUXii/0k08JZRctnoet1bvrk08v+Ab4=': {
    imageKey: 'illustrations/vault',
    srcPath: '/images/illustrations/vault@640w.png',
    width: 640,
  },
  'Z9+jpdvkPXNrG6zIhHvLqnshmEg5Rl/cUZYlu6jw20Q=': null,
  'z9Cm0WVL5nZ7OXjCZvq37nq7lFNnQG8/XF+6HfdDA5Q=': null,
  'ZBhQL5FcTSNcKU1xH9hj8UtgLq3puNIFNjPNFRvihpg=': {
    imageKey: 'illustrations/cat',
    srcPath: '/images/illustrations/cat@960w.webp',
    width: 960,
  },
  'zcAffqMkhXNbaaUvKbsIYDnPZ2J4zC3w0/WZyIOVWu4=': {
    imageKey: 'illustrations/identity',
    srcPath: '/images/illustrations/identity@750w.png',
    width: 750,
  },
  'zfbtcXWJdHKEymLm2ZzHZKm/pCdKdYmzx4xFk2HDyFE=': {
    imageKey: 'illustrations/estate-planning-family',
    srcPath: '/images/illustrations/estate-planning-family@640w.webp',
    width: 640,
  },
  'ZgeLSn3gl+5inNT1s3ab6/04EVxIrAyJtf7CODExisg=': null,
  'zL/6SgrGRfBQcxErvDv8O4RVkG7iLX+UC8tr1AMMpcg=': {
    imageKey: 'illustrations/crowd',
    srcPath: '/images/illustrations/crowd@640w.png',
    width: 640,
  },
  'zM7OJwUiEAxCuQbRmt0tc7PNW7bvBBEnWF3l7RNQElU=': null,
  'Zm9+FWi3bmj77VJZaJU/pS7/m12XFT0dbjHCp1HqvRU=': null,
  'zNq09bejgmpCFmYrVA923hcLnArBkecxDxuiK0m9vwc=': {
    imageKey: 'illustrations/lockedSafety',
    srcPath: '/images/illustrations/lockedSafety@640w.png',
    width: 640,
  },
  'zO0c/u4KEpm/9g/VZdOOG8eu7B3iHNPyMrQHWlSje7s=': {
    imageKey: 'illustrations/digitalExecutorOrders',
    srcPath: '/images/illustrations/digitalExecutorOrders@640w.webp',
    width: 640,
  },
  'zRx4EvwjsGk2gvEYu2p5NNP4p6EiB2zUAFYROCBayp4=': null,
  'zUMSXfg1Fp6f1/mpjFbSJQSQpowBeADhxRZqE7UATyo=': null,
  'zWmM+roKFpO5SDUOBA0lElewS0y2Xi9AdmhRNMERubg=': {
    imageKey: 'illustrations/file',
    srcPath: '/images/illustrations/file@320w.png',
    width: 320,
  },
  'zXlvDpwNohwMClvJTg3JhDQLPsxx6FrmlkvL7xVQDX8=': {
    imageKey: 'illustrations/family',
    srcPath: '/images/illustrations/family@320w.webp',
    width: 320,
  },
}
