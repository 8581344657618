import { motion } from 'framer-motion'
import React from 'react'
import { SquooshedPictureKey, squooshedPictures } from './pictures'

export const Picture: React.FC<
  React.PropsWithChildren<{
    /**
     * Thanks to the ts file generated by the squoosh script, we get type safety for Picture component, as well as auto suggestions.
     */
    imageKey: SquooshedPictureKey

    alt?: string
    width?: string | number
    className?: string
    imageComponent?: React.ComponentType<React.ComponentPropsWithoutRef<'img'>>
    motionImageComponent?: React.ComponentType<
      React.ComponentPropsWithoutRef<(typeof motion)['img']>
    >
    imageProps?: React.ComponentPropsWithoutRef<'img'>
    motionImageProps?: React.ComponentPropsWithoutRef<(typeof motion)['img']>

    /**
     * In order for the browser to pick the smallest image that still has same or higher resolution, we must provide the expected size of the image.
     * It doesn't need to be accurate, but the better approximation, the better the browser can decide which size it wants to download.
     * If we don't provide the sizes prop, the browser assumes the picture is 100vw large, which usually means a larger than needed image will be downloaded.
     * On the other hand, it might not make a huge difference, so keep that in mind when using an image and make the best decision for the specific use case.
     * See https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/sizes for more info.
     */
    sizes?: string
    useSource?: (descriptor: string) => boolean
  }>
> = React.memo((props) => {
  const data = squooshedPictures[props.imageKey]

  if (!data) {
    throw new Error(`Picture with key ${props.imageKey} not found.`)
  }
  const { alt = '', width, className } = props

  const propsPassedToImage = {
    src: data.fallbackSrc,
    alt,
    width,
    className,
    sizes: props.sizes,
  }

  const image = props.imageComponent ? (
    <props.imageComponent {...propsPassedToImage} {...props.imageProps} />
  ) : props.motionImageComponent ? (
    <props.motionImageComponent {...propsPassedToImage} {...props.motionImageProps} />
  ) : (
    <img {...propsPassedToImage} />
  )

  return (
    <picture>
      {data.sources.map((src, i) => (
        <source type={src.type} sizes={props.sizes} srcSet={src.srcset} key={i} />
      ))}
      {image}
    </picture>
  )
})
Picture.displayName = 'Picture'
